import {TransfersDataType} from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersData.type";

export const defaultTransfersData : TransfersDataType = {
    link: "",
    fromId: null,
    fromName: '',
    departureIsAirport: false,
    toId: null,
    toName: '',
    destinationIsAirport: false,
    distanceInKm: '',
    distanceInTime: '',
    minPrice: null,
    noDirectBuses: false,
    vehicles: [],
    departureNearbyCity: null,
    destinationNearbyCity:  null,
    strictPosition: null
}