import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconInPress(props: IconType) {
    const {
        width = '98',
        height = '90',
        fill = '',
        viewBox = '0 0 98 90',
        title = 'IconInPress',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={{title}}
            {...rest}
            svgIconPath={(
                <>
                    <g
                        clipRule="evenodd"
                        fillRule="evenodd"
                    >
                        <path
                            d="m74.0011 8h-45.0065c-1.6466.00479-3.2244.66102-4.3888 1.82534-1.1643 1.16436-1.8205 2.74216-1.8253 4.38876v.0065 56.8886h4.5v-56.8851c.0021-.4566.1844-.8939.5073-1.2168.323-.323.7605-.5053 1.2172-.5073h44.9922c.4567.002.8942.1843 1.2172.5073s.5053.7605.5073 1.2172v24.4814c0 1.2426 1.0073 2.25 2.25 2.25 1.2426 0 2.25-1.0074 2.25-2.25v-24.4851c0-.0002 0-.0002-2.25-.0002l2.25.0002v-.0067c-.0048-1.6466-.661-3.2244-1.8254-4.38876-1.1643-1.16432-2.7421-1.82055-4.3887-1.82533zm-23.5588 71.0735c0-1.2426-1.0074-2.25-2.25-2.25h-31.1021c-.0002 0-.0009 0-.0009 2.25s.0001 2.25.0002 2.25h31.1028c1.2426 0 2.25-1.0073 2.25-2.25z"
                            fill="#070c63"
                        />
                        <path
                            d="m27.2803 23.4844c0-1.2427-1.0074-2.25-2.25-2.25h-11.9118c-1.6498 0-3.23202.6554-4.39861 1.8219-1.16658 1.1666-1.82197 2.7489-1.82197 4.3987v43.6764c0 2.7029 1.07371 5.2951 2.98493 7.2063 1.91125 1.9112 4.50335 2.9849 7.20625 2.9849s5.295-1.0737 7.2062-2.9849c1.9113-1.9112 2.985-4.5034 2.985-7.2063zm-4.5 2.25v45.397c0 1.5094-.5996 2.957-1.6669 4.0243s-2.5149 1.6669-4.0243 1.6669-2.957-.5996-4.0243-1.6669-1.6669-2.5149-1.6669-4.0243v-43.6764c0-.4564.1813-.894.504-1.2167.3226-.3226.7603-.5039 1.2166-.5039z"
                            fill="#070c63"
                        />
                        <path
                            d="m48.8525 23.0829c0-1.2399-1.1851-2.245-2.647-2.245h-10.5883c-1.4619 0-2.647 1.0051-2.647 2.245s1.1851 2.245 2.647 2.245h10.5883c1.4619 0 2.647-1.0051 2.647-2.245z"
                            fill="#a4bcf1"
                        />
                        <path
                            d="m48.8525 33.6708c0-1.2399-1.1851-2.245-2.647-2.245h-10.5883c-1.4619 0-2.647 1.0051-2.647 2.245s1.1851 2.245 2.647 2.245h10.5883c1.4619 0 2.647-1.0051 2.647-2.245z"
                            fill="#a4bcf1"
                        />
                        <path
                            d="m56.7939 45.0636c0 1.2399-1.1851 2.245-2.647 2.245h-18.5294c-1.462 0-2.6471-1.0051-2.6471-2.245s1.1851-2.245 2.6471-2.245h18.5294c1.4619 0 2.647 1.0051 2.647 2.245z"
                            fill="#a4bcf1"
                        />
                        <path
                            d="m50.1768 55.6515c0 1.2399-1.1852 2.245-2.6471 2.245h-11.9118c-1.4619 0-2.647-1.0051-2.647-2.245s1.1851-2.245 2.647-2.245h11.9118c1.4619 0 2.6471 1.0051 2.6471 2.245z"
                            fill="#a4bcf1"
                        />
                    </g>
                    <path
                        d="m56.795 36.7202h10.5882c.7021 0 1.3753-.2788 1.8718-.7753.4964-.4964.7753-1.1697.7753-1.8717v-10.5883c0-.702-.2789-1.3753-.7753-1.8717-.4965-.4964-1.1697-.7753-1.8718-.7753h-10.5882c-.7021 0-1.3754.2789-1.8718.7753s-.7753 1.1697-.7753 1.8717v10.5883c0 .702.2789 1.3753.7753 1.8717.4964.4965 1.1697.7753 1.8718.7753z"
                        fill="#a4bcf1"
                    />
                    <path
                        d="m89.6416 68.2995c-.1293.6262-.7418 1.029-1.368.8997-.6196-.128-1.0205-.729-.9036-1.3482l.0039-.0198c.2059-.9969.3106-2.0185.3106-3.0543 0-8.2113-6.59-14.8834-14.7696-15.016l-.2484-.0021c-.6394 0-1.1577-.5183-1.1577-1.1577s.5183-1.1577 1.1577-1.1577c9.573 0 17.3335 7.7604 17.3335 17.3335 0 1.1933-.1208 2.3718-.3584 3.5226zm-28.4613 9.459c-.4787-.4239-.5232-1.1556-.0993-1.6343.4238-.4787 1.1555-.5232 1.6342-.0993 2.7279 2.4153 6.2333 3.77 9.9569 3.77 5.4434-.002 10.3752-2.9235 13.0336-7.5614.318-.5547 1.0255-.7467 1.5802-.4287s.7467 1.0255.4287 1.5802c-3.0668 5.3504-8.7603 8.723-15.042 8.7253-4.295 0-8.3449-1.565-11.4923-4.3518zm5.9184-29.4012c.6056-.2053 1.2629.1192 1.4682.7248.203.5991-.1124 1.2488-.7056 1.4614l-.0192.0067c-6.0452 2.0493-10.1936 7.7417-10.1936 14.2315.0009 2.715.7222 5.3218 2.0699 7.6086.3246.5508.1413 1.2606-.4096 1.5852-.5508.3247-1.2606.1413-1.5852-.4096-1.5562-2.6403-2.3896-5.6523-2.3906-8.7838 0-7.4899 4.7876-14.0593 11.7657-16.4248zm5.5678-.9139c.6394 0 1.1576.5183 1.1576 1.1577s-.5182 1.1577-1.1576 1.1577c-.059 0-.1181.0004-.177.0011-.6393.0073-1.1636-.505-1.171-1.1444-.0073-.6393.505-1.1636 1.1443-1.171.0678-.0007.1358-.0011.2037-.0011z"
                        fill="#7ed321"
                    />
                    <path
                        d="m63.9744 73.1054c-.4427-.4614-.4275-1.1943.0339-1.637.4561-.4375 1.1775-.4277 1.6216.0182l.0153.0158c2.4392 2.5423 6.0772 3.5947 9.542 2.6663 5.1356-1.3761 8.2042-6.6159 6.9163-11.7539l-.0403-.1557c-.1655-.6176.201-1.2525.8186-1.4179.6176-.1655 1.2525.201 1.418.8186 1.7209 6.4227-2.0906 13.0245-8.5133 14.7455-4.2887 1.1491-8.7941-.1542-11.8121-3.2999zm17.1022-16.9509c.4582.446.4681 1.179.0221 1.6372-.4411.4531-1.1629.4678-1.6219.0366l-.0152-.0146c-2.441-2.3759-5.9575-3.3284-9.308-2.4306-5.1876 1.39-8.2659 6.7222-6.8758 11.9103.0216.0805.0441.1605.0676.2402l.0239.0795c.1861.6117-.1589 1.2585-.7706 1.4446-.6117.1862-1.2585-.1588-1.4446-.7705-.0398-.1306-.0774-.262-.1129-.3944-1.7211-6.4234 2.0902-13.0253 8.5131-14.7463 4.147-1.1112 8.5024.0685 11.5223 3.008zm3.223 5.5047c.1655.6177-.2009 1.2525-.8186 1.418-.6111.1638-1.2392-.1934-1.4126-.7993l-.0053-.0193c-.0494-.1842-.1039-.3663-.1634-.5462l-.0302-.0897c-.2066-.6051.1164-1.2631.7215-1.4698.6051-.2066 1.2631.1164 1.4698.7215.0881.258.1678.5198.2388.7848z"
                        fill="#f5a623"
                    />
                    <path
                        d="m66.5279 61.9558c.2677-.5807.9554-.8345 1.5361-.5668.5744.2648.8289.9406.5752 1.5173l-.0084.0188c-.2651.5751-.4042 1.2025-.4042 1.8519 0 2.4467 1.9835 4.4302 4.4303 4.4302 2.4467 0 4.4302-1.9835 4.4302-4.4302 0-2.4223-1.944-4.3905-4.357-4.4297l-.0732-.0006c-.6394 0-1.1578-.5183-1.1578-1.1577s.5184-1.1578 1.1578-1.1578c3.7255 0 6.7457 3.0202 6.7457 6.7458 0 3.7255-3.0202 6.7457-6.7457 6.7457-3.7256 0-6.7458-3.0202-6.7458-6.7457 0-.9858.2123-1.9437.6168-2.8212zm6.1269-3.9245c.6394-.0002 1.1579.5179 1.1581 1.1574.0002.6327-.5072 1.147-1.1374 1.1579l-.02.0001c-.3189.0001-.6328.0337-.9382.0996l-.0572.0128c-.6232.1431-1.2444-.2461-1.3875-.8693s.246-1.2444.8692-1.3875c.4929-.1132.9996-.1709 1.513-.171z"
                        fill="#4a79e2"
                    />
                    <path
                        d="m86.0957 63.5547c.7189 0 1.3018.5828 1.3018 1.3017 0 .719-.5829 1.3018-1.3018 1.3018s-1.3018-.5828-1.3018-1.3018c0-.7189.5829-1.3017 1.3018-1.3017z"
                        fill="#4a79e2"
                    />
                </>
            )}
        />
    );
}

export default IconInPress;
