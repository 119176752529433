import React from 'react'
import IconCheckmark from "@Icons/IconCheckmark";
import NavigationContextAwareNavLink from "@Routing/components/NavigationContextAwareNavLink";

interface TransportSpecificLinkProps {
    url: string,
    text: string,
    isActive: boolean
}
function TransportSpecificLink({url, text, isActive}: TransportSpecificLinkProps) {
    function generateClassName() {
        let className = 'select__item '
        if (isActive) {
            className += 'select__item--selected'
        }
        if (!text || text === "") {
            className += 'select__item--disabled'
        }
        return className
    }

    return (
        <li
            key={url}
            className={generateClassName()}
        >
            {url ? (
                <NavigationContextAwareNavLink
                    routeName="name"
                    to={url}
                    className={`gb--row gb--row-middle gb--row-justify gb--row-gap ${isActive ? 'select__item--link select__item--link--selected' : 'select__item--link'}`}
                >
                    {text}
                    {isActive && <IconCheckmark width="16" height="16" fill="#7ed321"/>}
                </NavigationContextAwareNavLink>
            ) : (
                <span key={text}>
                    {text}
                </span>
            )}
        </li>
    )
}

export default TransportSpecificLink