import React, {ReactNode} from 'react'
import HorizontalCardWithLink from "@Generic/Cards/HorizontalCardWithLink";
import IconHelpdesk from "@Icons/IconHelpdesk";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useTranslation} from "@Translation/hooks/useTranslation";

interface HelpCenterSectionProps {
    className?: string
}

function HelpCenterSection({className}: HelpCenterSectionProps) {
    const {
        urlsObject,
    } = useAppSelector((state) => state?.page)

    const title = useTranslation("help_center_section.we_are_here")
    const content = useTranslation("help_center_section.all_info_in_one_place")
    const ctaLabel = useTranslation("help_center_section.help_center")

    return (
        <HorizontalCardWithLink
            title={title}
            content={content}
            icon={<IconHelpdesk className="help-center-icon"/>}
            link={urlsObject.helpCenter?.url}
            actionText={ctaLabel}
            className={className ? `${className} help-center` : "help-center"}
            btnClass="help-center-btn gb--review-btn"
        />
    )
}

export default HelpCenterSection