import CookieTitle from "@Cookies/components/CookieTitle";
import React, {MutableRefObject} from "react";
import Dialog from "@Features/dialog/components/Dialog";
import CookieActionButtons from "@Cookies/components/CookieActionButtons";
import CookieLinks from "@Cookies/components/CookieLinks";

type CookieModalDialogProps = {
    children?: React.ReactNode;
    dialogRef: MutableRefObject<HTMLDialogElement>,
}

function CookieDialog({children, dialogRef}: CookieModalDialogProps) {
    return (
        <Dialog dialogRef={dialogRef} className="gb--cookie-dialog">
            <CookieTitle/>
            <div className="gb--cookie-dialog-content">
                {children}
            </div>
            <hr/>
            <CookieLinks/>
            <CookieActionButtons/>
        </Dialog>
    );
}

export default CookieDialog;