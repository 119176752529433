import {setupListeners} from "@reduxjs/toolkit/query";
import {configureStore} from "@reduxjs/toolkit";
import searchResultsSlice from "@SearchResults/reducers/searchResultsSlice";
import checkOrderSlice from "@CheckOrder/reducers/checkOrderSlice";
import {checkOrderApi} from "@CheckOrder/api/checkOrderApi";
import filterSlice from "@SearchResults/reducers/filterSlice";
import userProfileSlice from "@MyProfile/reducers/userProfileSlice";
import {myProfileApi} from "@MyProfile/API/myProfileApi";
import {searchResultsApi} from "@SearchResults/api/searchResultsApi";
import mobileSlice from "@Reducers/mobileSlice";
import pageSlice from "@Reducers/pageSlice";
import formSlice from "@Form/reducers/formSlice";
import cookiesSlice from "@Cookies/reducers/cookiesSlice";

const store = configureStore({
    reducer: {
        page: pageSlice,
        form: formSlice,
        searchResults: searchResultsSlice,
        checkOrder: checkOrderSlice,
        filter: filterSlice,
        userProfile: userProfileSlice,
        mobile: mobileSlice,
        [myProfileApi.reducerPath]: myProfileApi.reducer,
        [searchResultsApi.reducerPath]: searchResultsApi.reducer,
        [checkOrderApi.reducerPath]: checkOrderApi.reducer,
        cookies: cookiesSlice,
    },
    middleware: (getDefaultMiddleware) => (
        getDefaultMiddleware().concat(
            myProfileApi.middleware,
            searchResultsApi.middleware,
            checkOrderApi.middleware,
        )
    ),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)

export default store;
