import CompanyLogo from "@Route/features/company/components/CompanyLogo";
import React from "react";
import Column from "@Containers/components/Column";

interface TicketCompanyDataProps {
    companyName: string,
    companyId: string,
    logoUrl?: string,
}

function TicketCompanyData({companyName, companyId, logoUrl}: TicketCompanyDataProps) {
    return (
        <Column className="company-data">
            <div className="gb--emphasize-default">
                {companyName}
            </div>
            <CompanyLogo
                companyId={companyId}
                companyName={companyName}
                className="gb--company-logo"
                logoUrl={logoUrl}
            />
        </Column>
    );
}

export default TicketCompanyData