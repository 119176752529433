import React, {useEffect} from 'react';
import {toggleCookiePreferences} from "@Cookies/reducers/cookiesSlice";
import useAcceptCookies from "@Cookies/hooks/useAcceptCookies";
import CookieTabs from "./CookieTabs";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import useDialog from "@Features/dialog/hooks/useDialog";
import Dialog from "@Features/dialog/components/Dialog";
import CookieActionButtons from "@Cookies/components/CookieActionButtons";
import CookieDialog from "@Cookies/components/CookieDialog";

function CookiePreferencesDialog() {
    const {
        cookiePreferencesActive,
    } = useAppSelector((state) => state.cookies)

    const {
        siteUrl,
    } = useAppSelector((state) => state.page)

    const dispatch = useAppDispatch()
    const {acceptEssentialCookies} = useAcceptCookies()
    const {dialogRef, openModal} = useDialog()

    const handleCookieAcceptance = () => {
        acceptEssentialCookies()
        dispatch(toggleCookiePreferences())
    }

    const handleClickOnElementOutsideOfReact = ({target: {id}}) => {
        if (id === 'activate-cookie-preferences') {
            dispatch(toggleCookiePreferences())
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClickOnElementOutsideOfReact);

        // cleanup this component
        return () => {
            window.removeEventListener('click', handleClickOnElementOutsideOfReact);
        };
    }, []);

    useEffect(() => {
        if (cookiePreferencesActive) {
            openModal()
        }
    }, [cookiePreferencesActive])

    if (cookiePreferencesActive) {
        return (
            <CookieDialog dialogRef={dialogRef}>
                <CookieTabs/>
            </CookieDialog>
        );
    }

    return null
}

export default CookiePreferencesDialog