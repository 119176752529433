import TicketType from "@MyTicket/types/Ticket.type";

const getNumberOfPassengers = (ticket: TicketType) => {
    const {
        baseRoutes,
        singleRoute,
        status,
    } = ticket

    const {
        isIntermodal,
    } = status

    return isIntermodal ? baseRoutes[0].numberOfPassengers : singleRoute.numberOfPassengers
}

export default getNumberOfPassengers