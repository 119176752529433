import React from "react";
import Row from "@Containers/components/Row";

type ToggleButtonProps = {
    checked: boolean,
    slim?: boolean
    disabled?: boolean,
    onClick: (e: React.MouseEvent<HTMLLabelElement>) => void,
    label?: string
    color?: "green" | "blue"
    additionalWrapperClassName?: string,
}

const ToggleButton = (props: ToggleButtonProps) => {
    const {
        checked,
        disabled,
        onClick,
        label,
        slim,
        color,
        additionalWrapperClassName = "",
    } = props

    return (
        <Row center className={`gb--toggle ${slim ? "gb--toggle-slim" : ""} ${additionalWrapperClassName}`}>
            <input
                type="checkbox"
                checked={checked}
                readOnly={disabled}
                onChange={() => null}
            />
            <label
                htmlFor="verifiedReviews"
                className={`gb--toggle-switch gb--toggle-switch-${color ?? 'blue'} gb--toggle-switch-left`}
                onClick={!disabled ? onClick : null}
            >
                <span className="gb--toggle-slider"/>
                {!slim && (
                    <span className="gb--toggle-mark"/>
                )}
            </label>
            {label && (
                <span>
                    {label}
                </span>
            )}
        </Row>
    );
}

export default ToggleButton;