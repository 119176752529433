import React, {useEffect} from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useIsLatestCookiePolicyAccepted from "@Cookies/hooks/useIsLatestCookiePolicyAccepted";
import useDialog from "@Features/dialog/hooks/useDialog";
import Translation from '@/features/translation/components/Translation';
import IconWorkWithUs from "@Icons/IconWorkWithUs";
import IconWatch from "@Icons/IconWatch";
import IconInPress from "@Icons/IconInPress";
import IconMailing from "@Icons/IconMailing";
import IconDiamond from "@Icons/FilledOutline/IconDiamond";
import CookieDialog from "@Cookies/components/CookieDialog";

function CookieNoticeDialog() {
    const {cookiePreferencesActive} = useAppSelector((state) => state?.cookies)
    const latestCookiePolicyAccepted = useIsLatestCookiePolicyAccepted()

    const {dialogRef, openModal} = useDialog()
    useEffect(() => {
        if (!latestCookiePolicyAccepted && !cookiePreferencesActive) {
            openModal()
        }
    }, [latestCookiePolicyAccepted, cookiePreferencesActive])

    if (!latestCookiePolicyAccepted && !cookiePreferencesActive) {
        return (
            <CookieDialog dialogRef={dialogRef}>
                <p>
                    <Translation translationKey="cookie.notice.we_use_cookies"/>
                </p>
                <ul>
                    <li>
                        <IconWorkWithUs height={25} width={25}/>
                        <Translation translationKey="cookie.notice.deliver_and_maintain"/>
                    </li>

                    <li>
                        <IconWatch height={25} width={25}/>
                        <Translation translationKey="cookie.notice.track_outages"/>
                    </li>

                    <li>
                        <IconInPress height={25} width={25}/>
                        <Translation translationKey="cookie.notice.measure_audience"/>
                    </li>
                </ul>
                <p>
                    <Translation translationKey="cookie.notice.if_you_accept_all"/>
                </p>
                <ul>
                    <li>
                        <IconDiamond height={25} width={25}/>
                        <Translation translationKey="cookie.notice.develop"/>
                    </li>
                    <li>
                        <IconMailing height={25} width={25}/>
                        <Translation translationKey="cookie.notice.measure_ads"/>
                    </li>
                </ul>
                <p>
                    <Translation translationKey="cookie.notice.if_you_reject"/>
                </p>
            </CookieDialog>
        );
    }

    return null
}

export default CookieNoticeDialog