import React from 'react';
import {toggleMobileLanguages} from "@Reducers/mobileSlice";
import IconChevronDown from "@Icons/IconChevronDown";
import LanguageFlag from "@Header/components/LanguageSwitcher/LanguageFlag";
import {useAsyncHandler} from "@Hooks/promises/useAsyncHandler";
import IconLogin from "@Icons/IconLogin";
import Translation from "@Translation/components/Translation";
import useUser from "@User/hooks/useUser";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useLogout from "../../hooks/useLogout";
import MobileMenuItemsList from "@User/components/Mobile/MobileMenuItemsList";
import MobileAdditionalMenuItems from "@User/components/Mobile/MobileAdditionalMenuItems";
import useIsModifiedMenuDisplayed from "@Header/hooks/useIsModifiedMenuDisplayed";

function MobileMenu() {
    const dispatch = useAppDispatch()
    const logout = useLogout()
    const handleLogout = useAsyncHandler(logout)
    const user = useUser()
    const isModifiedMenuDisplayed = useIsModifiedMenuDisplayed()

    const {
        locale,
    } = useAppSelector((state) => (state.page))

    const activateMobileLanguages = () => dispatch(toggleMobileLanguages())

    return (
        <ul className="gb--menu gb--menu-mobile">
            <li
                className="gb--menu__item gb--row gb--row-middle gb--row-justify"
                onClick={() => activateMobileLanguages()}
            >
                <span>
                    <LanguageFlag locale={locale}/>
                </span>
                <IconChevronDown
                    width="20"
                    height="26"
                    style={{
                        transform: 'rotate(-90deg)',
                        stroke: '#9b9b9b',
                        strokeWidth: '1',
                        verticalAlign: 'middle',
                    }}
                />
            </li>
            {isModifiedMenuDisplayed && (
                <MobileAdditionalMenuItems/>
            )}
            <MobileMenuItemsList/>
            {user && (
                <li
                    className="gb--menu__item gb--row gb--row-middle gb--row-justify"
                    onClick={handleLogout}
                >
                    <span>
                        <IconLogin
                            width="18"
                            height="18"
                            fill="#9b9b9b"
                            style={{
                                verticalAlign: 'text-top',
                                marginRight: '1rem',
                            }}
                        />
                        <Translation translationKey="check_order.buyer_info_section.log_out"/>
                    </span>
                    <IconChevronDown
                        width="20"
                        height="26"
                        style={{
                            transform: 'rotate(-90deg)',
                            stroke: '#9b9b9b',
                            strokeWidth: '1',
                            verticalAlign: 'middle',
                        }}
                    />
                </li>
            )}
        </ul>
    );
}

export default MobileMenu;
