import Row from "@Containers/components/Row";
import React from "react";
import useIsMobile from "@Hooks/selector/useIsMobile";
import TicketStatusType from "@MyTicket/types/TicketStatus.type";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import TransportData from "@MyTicket/components/TransportData";

interface TransportDataInfoProps {
    status: TicketStatusType,
    singleRoute: TicketRouteType,
    returnRoute: TicketRouteType,
    baseRoutes: TicketRouteType[]
}

function TransportDataInfo(props: TransportDataInfoProps) {
    const {
        status,
        baseRoutes,
        singleRoute,
        returnRoute,
    } = props

    const isMobile = useIsMobile()

    if (isMobile) {
        return (
            <TransportData
                status={status}
                outboundRoute={singleRoute}
                returnRoute={returnRoute}
                baseRoutes={baseRoutes}
            />
        );
    }

    return (
        <Row justify className="transport-data-info info-field-very-large">
            <TransportData
                status={status}
                outboundRoute={singleRoute}
                returnRoute={returnRoute}
                baseRoutes={baseRoutes}
            />
        </Row>
    );
}

export default TransportDataInfo