import {IconColoursType} from "@Features/icons/types/IconColoursType";

export default function setIconColour(iconFill: IconColoursType) {
    const fillColour = {
        [`${iconFill === "navy"}`]: '#b5b7d1',
        [`${iconFill === "green"}`]: '#CCEBB6',
        [`${iconFill === "orange"}`]: '#FCE1B4',
        [`${iconFill === "blue"}`]: '#C9D7F6',
        [`${iconFill === "red"}`]: '#FFC8BE',
    }

    if (fillColour.true) {
        return `${fillColour.true}`;
    }

    return '#fff';
}