import TicketType from "@MyTicket/types/Ticket.type";
import ModalDataType from "@MyTickets/context/types/ModalData.type";

export default function prepareTicketModalData(ticket: TicketType): ModalDataType {
    const {
        id,
        baseRoutes,
        ticketCode,
        singleRoute,
        returnRoute,
        payment,
        status,
        ancillariesData,
    } = ticket

    const {
        isIntermodal,
        isCancelled,
        isCancelable,
        isRebookable,
    } = status

    return {
        ticketId: `${id}`,
        ticketCode,
        rebookAmount: payment.rebookAmount,
        isCancelable,
        isRebookable,
        isCancelled,
        singleRoute: (isIntermodal ? {
            companyId: baseRoutes[0].companyId,
            departure: baseRoutes[0].departureStation.name,
            destination: baseRoutes[baseRoutes.length - 1].destinationStation.name,
            departureDate: baseRoutes[0].departureStation.departureDate,
            departureTime: baseRoutes[0].departureStation.departureTime,
        } : {
            companyId: singleRoute.companyId,
            departure: singleRoute.departureStation.name,
            destination: singleRoute.destinationStation.name,
            departureDate: singleRoute.departureStation.departureDate,
            departureTime: singleRoute.departureStation.departureTime,
        }),
        returnRoute: (!!returnRoute && {
            companyId: returnRoute.companyId,
            departure: returnRoute.departureStation.name,
            destination: returnRoute.destinationStation.name,
            departureDate: returnRoute.departureStation.departureDate,
            departureTime: returnRoute.departureStation.departureTime,
        }),
        ancillariesData,
    }
}