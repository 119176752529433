import {isLastElement} from "@Array/isLastElement";
import React from "react";
import TicketType from "@MyTicket/types/Ticket.type";
import TicketIntermodalData from "@MyTicket/features/ticketDetails/components/TicketIntermodalData";
import {has24HoursPassed} from "@MyTicket/features/ticketDetails/functions/has24HoursPassed";
import TicketData from "@MyTicket/features/ticketDetails/components/TicketData";

interface TicketDataSectionProps {
    ticket: TicketType,
}

function TicketDataSection({ticket}: TicketDataSectionProps) {
    const {
        id,
        baseRoutes,
        singleRoute,
        returnRoute,
        status,
    } = ticket

    const {
        isCancelled,
        isExchangedForVoucher,
        isIntermodal,
        isReturn,
    } = status

    if (isIntermodal) {
        return (
            <>
                {baseRoutes.map((route, index) => {
                    const hasTransit = !isLastElement(baseRoutes, index)

                    return (
                        <TicketIntermodalData
                            key={`${id}-${route.id}`}
                            isCancelled={isCancelled}
                            isExchangedForVoucher={isExchangedForVoucher}
                            isExpired={has24HoursPassed(route.departureStation.departureDateTime)}
                            isMTicket={route.hasMTicket}
                            ticketId={`${id}`}
                            currentRoute={route}
                            nextRoute={hasTransit && baseRoutes[index + 1]}
                            hasTransit={hasTransit}
                        />
                    )
                })}
            </>
        );
    }

    return (
        <>
            <TicketData
                isCanceled={isCancelled}
                isExchangedForVoucher={isExchangedForVoucher}
                isExpired={has24HoursPassed(singleRoute.departureStation.departureDateTime)}
                isMTicket={singleRoute.hasMTicket}
                id={`${id}`}
                route={singleRoute}
            />
            {isReturn && (
                <TicketData
                    isCanceled={isCancelled}
                    isExchangedForVoucher={isExchangedForVoucher}
                    isExpired={has24HoursPassed(returnRoute.departureStation.departureDateTime)}
                    isMTicket={singleRoute.hasMTicket}
                    id={`${id}`}
                    route={returnRoute}
                />
            )}
        </>
    );
}

export default TicketDataSection;