/**
 * Returns filtered array, used in auto-suggest or
 * any other place where want to filter and map values in one step
 * * First value in keys array is considered UNIQUE, Set will filter out any duplicates
 */
export default function filterByKeys<T>(array: T[], searchTerm: string, keys: any[]): T[] {
    let allResults = []
    if (searchTerm) {
        keys.map((key) => {
            const keyResults = array.reduce((acc, item) => {
                if (item[key].toLowerCase().includes(searchTerm.toLowerCase())) {
                    return [...acc, item];
                }
                return acc;
            }, []);
            allResults = [...allResults, ...keyResults]
        })

        const seen = new Set();
        return allResults.filter((el) => {
            const duplicate = seen.has(el[keys[0]]);
            seen.add(el[keys[0]]);
            return !duplicate;
        })
    }

    return array
}
