import React from 'react'
import Column from "@Containers/components/Column";
import IconChevronDown from "@Icons/IconChevronDown";
import useIsMobile from "@Hooks/selector/useIsMobile";
import {useToggle} from "@Hooks/dispatch/useToggle";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";

interface FooterSectionProps {
    title: string,
    items: UrlObjectType[]
}

function FooterSection({title, items}: FooterSectionProps) {
    const isMobile = useIsMobile()
    const isTablet = useIsSmallTablet()
    const isExpanded = useToggle()

    return (
        <Column alignStart className="gb-footer-additional-section">
            <button
                onClick={() => isExpanded.onChange()}
                className="gb--row gb--row-justify gb--row-middle "
            >
                <h5>{title}</h5>
                {(isMobile || isTablet) && (
                    <IconChevronDown
                        style={{
                            transform: `rotate(${isExpanded.value ? '-180' : '0'}deg)`,
                            stroke: '#9b9b9b',
                            strokeWidth: '1',
                            verticalAlign: 'middle',
                        }}
                    />
                )}
            </button>
            {(isExpanded.value || !(isMobile || isTablet)) && (
                <>
                    {items.map((item) => (
                        <div key={item.url ?? item.text}>
                            {item.url ? (
                                <a href={item.url}>{item.text}</a>
                            ) : (
                                <span>{item.text}</span>
                            )}
                        </div>
                    ))}
                </>
            )}
        </Column>
    )
}

export default FooterSection