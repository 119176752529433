import Row from "@Containers/components/Row";
import IntermodalTransportTypeIcons from "@Intermodal/components/IntermodalTransportTypeIcons";
import Column from "@Containers/components/Column";
import React, {ReactNode} from "react";
import RouteTransferType from "@Route/features/transportType/types/RouteTransfer.type";

interface TicketPriceProps {
    isIntermodal: boolean,
    isCancelled: boolean,
    routeTransferTypeArray: RouteTransferType[],
    children: ReactNode,
    priceInCurrency: string,
}

function TicketPrice(props: TicketPriceProps) {
    const {
        children,
        isIntermodal,
        isCancelled,
        routeTransferTypeArray,
        priceInCurrency,
    } = props

    const getPrice = () => {
        if (isCancelled) {
            return `- ${priceInCurrency}`
        }

        return priceInCurrency
    }

    return (
        <Column alignEnd className="price-and-passengers-info info-field-small">
            <Row className="gb--emphasize-default">
                {isIntermodal && (
                    <IntermodalTransportTypeIcons
                        routeTransferTypeArray={routeTransferTypeArray}
                    />
                )}
                <div className={`ticket-price ${isCancelled ? "price-cancelled" : ""}`}>
                    {getPrice()}
                </div>
            </Row>
            {children}
        </Column>
    );
}

export default TicketPrice