import rootExtractor from "@Dataset/rootExtractor";
import LocationToLocationCountryImageType
    from "@Pages/stationToStation/features/header/types/LocationToLocationCountryImage.type";

export default function getCountryLocationImagePaths(): LocationToLocationCountryImageType|null {
    try {
        return JSON.parse(rootExtractor('locationToLocationCountryImage')) as LocationToLocationCountryImageType
    } catch (e) {
        return null
    }
}