import React from 'react';
import TopBar from "./TopBar/TopBar";
import MobileOverlayContainer from "@MobileOverlays/components/MobileOverlayContainer";
import useIsMobile from "@Hooks/selector/useIsMobile";
import useIsDesktop from "@Hooks/selector/useIsDesktop";
import useIsSmallTablet from "@Hooks/selector/useIsSmallTablet";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import MobileNavigation from "./TopBar/MobileNavigation";
import Logo from "./TopBar/Logo";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {Outlet} from "react-router";
import useToggleDOMElements from "@Features/domElements/hooks/useToggleDOMElements";
import useRouteMatch from "@Routing/hooks/useRouteMatch";
import UserAccount from "@User/components/Modals/UserAccount";
import PortalPagesContainer from "@Routing/components/PortalPagesContainer";
import CookiePreferencesDialog from "@Cookies/components/CookiePreferencesDialog";
import CookieNoticeDialog from "@Cookies/components/CookieNoticeDialog";
import getCookieExpiryTime from "@Cookies/functions/getCookieExpiryTime";
import {CookiesProvider} from "react-cookie";

interface HeaderProps {
    includeOutlet?: boolean,
}

function Header({includeOutlet = true}: HeaderProps) {
    useToggleDOMElements()
    // useUpdateHistoryParameters()
    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    const isMyProfile = useRouteMatch('myProfile')
    const isDesktop = useIsDesktop()
    const formVisible = useAppSelector((state) => state?.form.formVisible)
    const isCheckOrder = useRouteMatch('checkOrder')
    const isCompanyStore = useIsCompanyStore()
    const {formInputSelected, mobileOverlayActive} = useAppSelector((state) => state?.mobile)
    const isSearchResults = useRouteMatch('searchResults')
    const {loginModalActive} = useAppSelector((state) => state.userProfile)

    // TODO: this is dumb, stop doing it
    const showOverlay = () => {
        if (isMobile || isSmallTablet) {
            if (formVisible) {
                if ((isSearchResults || isMyProfile) && !isSmallTablet) {
                    return 'gb--header__overlay'
                }
                if (formInputSelected) {
                    return `gb--search-2__select--mobile`
                }
                return ''
            }
            return ''
        }
        if (!isCompanyStore) {
            return 'gb--navigation'
        }
    }

    if (!isDesktop && mobileOverlayActive) {
        return (
            <MobileOverlayContainer/>
        )
    }
    // TODO: fix the rest of this garbage so we don't relly on conditions for rendering but routes
    return (
        <>
            <div className={showOverlay()}>
                {!isMobile && (
                    <TopBar/>
                )}

                {(isMobile && !isSearchResults && !isCheckOrder && !isMyProfile) && (
                    <header className="gb--header gb--row gb--row-middle gb--row-justify">
                        <Logo/>
                    </header>
                )}

                {isMobile && !isCheckOrder && (
                    <MobileNavigation/>
                )}
                {loginModalActive && (
                    <UserAccount/>
                )}
            </div>
            {includeOutlet && (
                <Outlet/>
            )}
            <PortalPagesContainer/>
            <CookiesProvider
                defaultSetOptions={{
                    path: '/',
                    expires: getCookieExpiryTime(),
                }}
            >
                <CookiePreferencesDialog/>
                <CookieNoticeDialog/>
            </CookiesProvider>
        </>
    );
}

export default Header
