type ConditionType = { bool: any, method: (value: any) => boolean }

/**
 * Creates condition object for filter
 */
export const addCondition = (bool: any, method: (value: any) => any): ConditionType => ({
    bool,
    method,
})

/**
 * Iterates upon array with multiple condition objects
 */
export const filterArrayByConditions = <T>(array: T[], conditions: ConditionType[]): T[] => (
    array.filter((item) => {
        const allConditionsMet = conditions.every((condition) => (
            condition.bool ? condition.method(item) : true
        ))

        if (allConditionsMet) {
            return item
        }
    })
)
