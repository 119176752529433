import Row from "@Containers/components/Row";
import GetByApsLogomark from "@Generic/Logotypes/GetByApsLogomark";
import Translation from "@Translation/components/Translation";
import LanguageSwitcher from "@Header/components/LanguageSwitcher/LanguageSwitcher";
import React from "react";
import useIsDesktop from "@Hooks/selector/useIsDesktop";

type CookieTitleProps = {}

function CookieTitle(props: CookieTitleProps) {
    const isDesktop = useIsDesktop()
    return (
        <Row justify center>
            <Row center>
                <GetByApsLogomark/>
                <span className="gb--cookie-dialog-title">
                        <Translation translationKey="cookie.notice.privacy_settings"/>
                </span>
            </Row>
            {isDesktop && (
                <ul className="gb--menu gb--menu__nav gb--row gb--row-middle gb--row-end">
                    <LanguageSwitcher/>
                </ul>
            )}
        </Row>
    );
}

export default CookieTitle;