import useLocale from "@Hooks/selector/useLocale";
import cs from "date-fns/locale/cs"
import de from "date-fns/locale/de"
import enGb from "date-fns/locale/en-GB"
import enUs from "date-fns/locale/en-US"
import es from "date-fns/locale/es"
import fr from "date-fns/locale/fr"
import hr from "date-fns/locale/hr"
import hu from "date-fns/locale/hu"
import it from "date-fns/locale/it"
import nl from "date-fns/locale/nl"
import no from "date-fns/locale/nn"
import pl from "date-fns/locale/pl"
import pt from "date-fns/locale/pt"
import ptBr from "date-fns/locale/pt-BR"
import sl from "date-fns/locale/sl"
import sr from "date-fns/locale/sr-Latn"
import sv from "date-fns/locale/sv"

/**
 * returns date-fns locale (or en-gb) for date-picker
 */
export default function useDatePickerLocale() {
    const locale = useLocale()

    const allLocales = {
        [`${locale === 'cs'}`]: cs,
        [`${locale === 'bs'}`]: hr,
        [`${locale === 'de'}`]: de,
        [`${locale === 'en' || locale === 'en-gb'}`]: enGb,
        [`${locale === 'en-us'}`]: enUs,
        [`${locale === 'es' || locale === 'es-ar' || locale === 'es-mx'}`]: es,
        [`${locale === 'fr'}`]: fr,
        [`${locale === 'hr'}`]: hr,
        [`${locale === 'hu'}`]: hu,
        [`${locale === 'it'}`]: it,
        [`${locale === 'nl'}`]: nl,
        [`${locale === 'no'}`]: no,
        [`${locale === 'pl'}`]: pl,
        [`${locale === 'pt'}`]: pt,
        [`${locale === 'pt-br'}`]: ptBr,
        [`${locale === 'sl'}`]: sl,
        [`${locale === 'sr'}`]: sr,
        [`${locale === 'sv'}`]: sv,
    }

    return allLocales?.true || enGb
}