import useQuantityAwareTransportTypeTranslation from "@Translation/hooks/useQuantityAwareTransportTypeTranslation";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";

export default function useIntermodalTransportData(baseRoutes: TicketRouteType[]) {
    const counts = baseRoutes.reduce((acc, baseTicket) => {
        acc[baseTicket.transportTypeId] = 1 + acc[baseTicket.transportTypeId] || 1;
        return acc;
    }, {})

    // go through each transport type found in base tickets, and create an array of "{count} {transport_type}" strings and join them
    // example: ["1 bus ticket","5 ferry tickets", "2 train tickets"].join,
    const usedTransportTypesDescription = Object.keys(counts).map((transportTypeId) => (

        // TODO fix this usage to follow the hooks rule-set
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useQuantityAwareTransportTypeTranslation(transportTypeId, counts[transportTypeId])
    )).join(", ")

    const selfTransferStations = baseRoutes.slice(0, -1).map((route) => (
        route.destinationStation.name
    )).join(", ")

    return {
        usedTransportTypesDescription,
        selfTransferStations,
    }
}