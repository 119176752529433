import React from "react";

/**
 * used to disable keyboard prompt on mobile date pickers
 */
export default function handleMobileKeyboardOnFocus(e: React.FocusEvent<HTMLInputElement>) {
    const {target} = e;

    if (target) {
        target.readOnly = true // -------> this for all others
        target.blur() //  ------> this for ios iphone
    }
}