import setCookieForThirtyYears from "@Cookies/functions/setCookieForThirtyYears";
import useVisitorIdCookie from "@Cookies/hooks/useVisitorIdCookie";
import {useCookies} from "react-cookie";

export default function useSetCookieByKey() {
    const {setSelfRefreshingCookie} = useVisitorIdCookie()
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const updateWindowValue = (key, value) => {
        window[key] = value ? 'granted' : 'declined'
    }

    const setCookieByKey = (cookie, value) => {
        updateWindowValue(cookie, value)
        if (cookie === 'visitor_id') {
            if (value === false) {
                removeCookie(cookie)
            } else {
                setSelfRefreshingCookie()
            }
        } else {
            setCookieForThirtyYears(setCookie, cookie, value)
        }
    }
    return {
        setCookieByKey,
        updateWindowValue,
    }
}