import {createSlice} from '@reduxjs/toolkit'
import getCookie from "@Cookies/functions/getCookie";
import getActiveCookiesFromCookieData from "@Cookies/functions/getActiveCookiesFromCookieData";
import {CookieDataType} from "@Cookies/types/CookieData.type";
import rootExtractor from "@Dataset/rootExtractor";

const {
    cookieKeys,
    cookieData,
} = getActiveCookiesFromCookieData()

type CookieSliceStateType = {
    cookiesConsent: boolean,
    cookiePreferencesActive: boolean,
    cookieData: CookieDataType,
    cookieVersion: string,
}

const initialState: CookieSliceStateType = {
    cookiesConsent: getCookie("cookiesConsent") ?? false,
    essential_cookies: getCookie("essential_cookies") ?? true,
    cookieVersion: rootExtractor('cookieVersion'),
    cookiePreferencesActive: false,
    cookieData,
    ...cookieKeys,
}

const cookiesSlice = createSlice({
    name: 'cookies',
    initialState,
    reducers: {
        clear: () => initialState,

        toggleCookiePreferences: (state) => {
            state.cookiePreferencesActive = !state.cookiePreferencesActive
        },

        setCookieState: (state, action) => ({
            ...state,
            ...action.payload,
        }),
    },
})

export const {
    toggleCookiePreferences,
    setCookieState,
    clear,
} = cookiesSlice.actions

export default cookiesSlice.reducer