import RouteTransferType from "@Route/features/transportType/types/RouteTransfer.type";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";

export const createRouteTransferTypeArray = (baseRoutes: TicketRouteType[]): RouteTransferType[] => (
    baseRoutes?.map((route) => (
        {
            id: route.id,
            transportTypeId: route.transportTypeId,
        }
    ))
)