import React from "react";
import AncillariesType from "@CheckOrder/types/Ancillaries.type";
import Ancillary from "@MyTicket/features/ticketDetails/components/Ancillary";
import Row from "@Containers/components/Row";

interface AncillariesDetailsProps {
    ancillariesData: AncillariesType
}

function AncillariesDetails({ancillariesData}: AncillariesDetailsProps) {
    if (!ancillariesData) {
        return;
    }
    return (
        <Row className="ancillaries-container">
            {Object.values(ancillariesData).map((ancillaryData) => (
                <Ancillary
                    key={ancillaryData.id}
                    ancillaryData={ancillaryData}
                />
            ))}
        </Row>
    );
}

export default AncillariesDetails