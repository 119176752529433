import React from 'react'
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import TransportSpecificLink from "@Header/components/TransportSpecificLinks/TransportSpecificLink";

interface TransportSpecificLinksDropdownProps {
    items: UrlObjectType[],
}

function TransportSpecificLinksDropdown({items}: TransportSpecificLinksDropdownProps) {
    return (
        <ul className="select__list select__list--opened">
            {items.map((item) => (
                <TransportSpecificLink
                    url={item.url}
                    text={item.text}
                    isActive={item.url === window.location.pathname || item.url === window.location.href}
                    key={item.url ?? item.text}
                />
            ))}
        </ul>
    )
}

export default TransportSpecificLinksDropdown