import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {IconColoursType} from "@Features/icons/types/IconColoursType";
import setIconColour from "@Features/icons/functions/setIconColour";

type IconColorChangeProp = IconType & {
    fillColour?: IconColoursType,
}

function IconSale(props: IconColorChangeProp) {
    const {
        width = '16',
        height = '16',
        viewBox = '0 0 16 16',
        fillColour = "navy",
        outline = '#070c63',
        title = 'IconSale',
        ...rest
    } = props

    const fill = setIconColour(fillColour as IconColoursType)

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            outline={outline}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(2 1)">
                    <path
                        d="m3.13942698 6.71212383-.34527596-.23311976c.10691724-.15233568.07646051-.34458255-.07027797-.44363921l3.58481881-5.1071115c.34589863-.49278766 1.0173489-.6337174 1.49209537-.31317293l1.2084634.81592877c-.45283057.64510893-.31697159 1.50280499.30451826 1.92242536.62148986.41962037 1.48694731.23798123 1.93972891-.40712769l.8632021.58280901c.4747465.32053967.5801586.98601772.2342599 1.47880057l-3.58479427 5.10709225c-.14674338-.0991047-.34075059-.0583761-.44763351.0939596l-.34526125-.23311977c.10688292-.15233568.076436-.34458254-.07030739-.44363921-.14674338-.09910475-.34070156-.05837611-.44763351.09391149l-.34526125-.23311976c.10693195-.1522876.076436-.34453446-.07030739-.44363921-.14669436-.09905666-.34070156-.05832802-.44758448.09395957l-.34531028-.23311976c.10693195-.15228759.07648502-.34453446-.07025836-.44363921-.14674339-.09905666-.34070156-.05832802-.44763351.09395958l-.34526125-.23311976c.10688292-.15233568.07643599-.34453446-.07030739-.44363921-.14674339-.09905666-.34070157-.05837611-.44763351.09395957l-.34526125-.23311976c.10693195-.15233568.07648502-.34458254-.07025836-.44363921-.14674339-.09905666-.3407506-.05837611-.44763352.09395958l-.34526124-.23311976c.10688292-.15233569.07643599-.34458255-.07030739-.44363921-.14673848-.09910475-.34071137-.05837611-.44762371.09395957z"
                        fill="#fff"
                    />
                    <path
                        d="m.8830599 10.4708914c-.47475137-.3205396-.58017323-.98599846-.23426971-1.47878132l1.69806562-2.41919452c.14674338.09910475.34071627.05837611.44763351-.09391149l.34527106.23311976c-.10691724.1522876-.07646051.34453446.07028287.44363921.14673848.09905666.34072608.05832802.447609-.09395957l.34531028.23311976c-.10693195.15228759-.07648503.34453446.07025836.44363921.14674338.09905666.34070156.05837611.44763351-.09395958l.34526125.23311976c-.10688292.15233568-.076436.34453446.07030739.44363921.14674338.09905666.34070156.05837611.44763351-.09395957l.34526125.23311976c-.10693195.15233568-.07648503.34458254.07025836.44363921.14674338.09905666.34075059.05837611.44763351-.09395958l.34526125.23311976c-.10688292.15233569-.076436.34458255.07030739.44363921.14674338.09910475.34070156.05837611.44763351-.09395957l.34526125.23311976c-.10693195.15233568-.076436.34458255.07025836.44363919.14674338.0991048.34075059.0583761.44763351-.09391147l.34531028.23311977c-.10693195.1522876-.07648503.3445345.07025836.4436392l-1.69806562 2.4191464c-.34589862.4927829-1.01734889.6337223-1.49209537.3131345z"
                        fill={fill}
                    />
                    <g fill={outline}>
                        <path
                            d="m6.94969521 13.2465457 1.25783546-1.7919639c.10693195-.1522876.076436-.3445345-.07030738-.4436392-.14674339-.0990567-.34070157-.0583281-.44763352.0939595l-1.25778643 1.7919639c-.2452914.3494392-.70782969.4465244-1.04446186.219223l-4.3159372-2.9140451c-.33664198-.22725325-.40926353-.68570288-.16398684-1.03514209l1.25782566-1.79196388c.10691724-.1522876.07646051-.34453446-.07027797-.44363921-.14674339-.09905667-.34071628-.05832803-.44762861.09395957l-1.25783056 1.79196389c-.45281587.64511853-.31694218 1.50282422.30454768 1.92242052l4.31591269 2.9140451c.62148986.4196444 1.48689831.2379764 1.93972888-.4071421z"/>
                        <path
                            d="m7.05098884 13.3147313 1.25783546-1.7919639c.14154633-.2016236.10609846-.4705192-.10173489-.6108334-.20783334-.1403142-.47656054-.076793-.61810686.1248306l-1.25783547 1.7919639c-.21062799.3001032-.59844629.3744438-.8739885.1883519l-4.31591269-2.9139969c-.27556672-.18609194-.34319223-.56789286-.13253482-.86799608l1.25783056-1.79196389c.14153162-.20162358.10607885-.47051915-.10173979-.61083339s-.47655564-.07679296-.61809216.12483063l-1.25783056 1.79196389c-.48743515.69440644-.34656051 1.62876084.3360095 2.08961474l4.31589799 2.913997c.68257982.460902 1.62275728.2564894 2.11020223-.4379651zm1.15688504-1.8601014-1.25783547 1.7919639c-.45283057.6450704-1.31823902.8267384-1.93972888.407094l-4.31591759-2.913997c-.62149477-.4196444-.75736845-1.27735009-.30454768-1.92246862l1.25782565-1.79196389c.10691724-.1522876.30089013-.19301624.44762861-.09391149.14674339.09905667.17719521.29130353.07028288.44363921l-1.25783056 1.79196389c-.24527669.34939112-.17265514.80784075.16398683 1.0351421l4.31589799 2.913997c.33668119.2273014.79921948.1302162 1.04446185-.219223l1.25783546-1.7919158c.10693195-.1523357.30089013-.1930643.44763352-.0939595.14674338.0990566.17719031.2913035.07030739.4436392z"
                        />
                        <path
                            d="m8.94781747 9.31293826 3.14456413-4.47986163c.2452914-.3494392.1726306-.80787921-.1640015-1.03517578l-.630119-.42544837c-.5862381.58143376-1.4994987.70436019-2.17276304.24977186-.67331336-.45459315-.88217632-1.33516361-.53760147-2.07978601l-.97542922-.65856812c-.33663217-.22729658-.79917045-.13021143-1.04446185.21922297l-3.14456415 4.47988086c-.10691233.15233569-.30088522.19306432-.44762861.09395958-.14673848-.09905667-.17719521-.29130353-.07027797-.44363922l3.14457885-4.47988567c.45283058-.64510892 1.31823903-.82674806 1.93972888-.40712769l1.2084634.81592877c.14674339.0990759.17719031.29131795.07025836.4436344-.34589862.49279248-.24048657 1.15824648.23425991 1.47879096.47474647.32054448 1.14619671.17961473 1.49209541-.31317774.1069319-.15231645.3008901-.19302585.4476335-.09394995l.8632021.5828042c.6214899.41962037.7573489 1.27732605.3045183 1.92244459l-3.14456416 4.47990972c-.10688292.15228759-.30089013.19301623-.44763351.09391148-.14674339-.09905666-.17719031-.29130352-.07025836-.44363921z"
                        />
                        <path
                            d="m11.9912841 4.76489103-3.14456415 4.47990971c-.14154632.20162359-.10609846.47051915.10173489.6108334.20778432.14031424.47656054.07684104.61810687-.12483063l3.14456419-4.47986163c.4874449-.69445453.346536-1.62877525-.3360439-2.08963397l-.8631531-.58280901c-.2078333-.14031425-.4765605-.07683143-.6181069.12480659-.3112842.44347091-.90796546.56162723-1.32162197.2823211s-.51411665-.86813067-.20283241-1.31160158c.14154633-.20163801.10609846-.47050472-.10173489-.61082377l-1.20846339-.81592878c-.6825308-.46085776-1.62275729-.25644616-2.11015321.43797952l-3.14458866 4.4799049c-.14153652.20162359-.10607884.47051915.1017398.6108334.20781863.14031424.47655563.07684104.61807745-.12483063l3.14456414-4.47989048c.21067702-.30010803.59844629-.37442457.87403753-.18836634l.89310975.60302907c-.30853863.77188703-.07893647 1.66350278.61987191 2.13532025.69880837.47180304 1.63001355.36396591 2.25503345-.194228l.5478976.36990932c.2755422.18605822.343153.56785434.132525.86795756zm-.6931701-1.39242413.630119.42544837c.3366812.22729658.4092929.68572216.1640015 1.03516136l-3.14456412 4.47990972c-.10693195.1522876-.07648503.34453446.07025836.44363921.14674338.09905666.34075059.05832802.44763351-.09395957l3.14456415-4.47990972c.4528306-.64511854.3169716-1.50280979-.3045183-1.92243016l-.8632021-.58280421c-.1467434-.0990807-.3407016-.05836649-.4476335.09394996-.3458987.49278766-1.01734893.6337174-1.4920954.31317293-.47474647-.32053967-.58015853-.98599849-.2342599-1.47878615.10693195-.15231645.07648502-.3445585-.07025836-.4436344l-1.2084634-.81593358c-.62148986-.41962038-1.48689831-.23797642-1.93972888.40712769l-3.14457885 4.47992414c-.10691234.1522876-.07646051.34453446.07028287.44363921.14673848.09905666.34071137.05832803.44762861-.09395957l3.14455924-4.47991933c.2452914-.34942959.70782969-.44651474 1.04446186-.21922298l.97542921.65857294c-.34457484.74461759-.13571189 1.62519286.53760148 2.07978119.67326433.45458834 1.58652492.33166671 2.17276302-.24976705z"
                        />
                        <path
                            d="m5.40646274 6.44245889c-.0113747.0621268-.00166698.12795621.02049406.18532252.06829721.16166432.26269665.22783032.42709042.16051026l3.30763414-1.4502184c.16444281-.06732006.23195555-.25855712.16365835-.42022144-.06829721-.16166432-.26269665-.22787841-.42709042-.16051027l-3.30763414 1.4502184c-.09560628.04731639-.1652763.13132221-.18415241.23489893z"
                        />
                        <path
                            d="m7.51225238 6.81228646c-.08320198.45575683.22778809.89521258.69111084.97657368s.91046597-.22494518.99366795-.680702-.22778809-.89521257-.69111084-.97657368c-.46332275-.0813611-.91046597.22494518-.99366795.680702zm1.05299287.18493783c-.02078823.11391517-.13257404.19051578-.24842924.1701755-.11580618-.02034027-.19356595-.13021623-.17277771-.24417948.02078823-.11391516.13262307-.19051578.24842924-.17012741.11580618.02034027.19356595.13016814.17277771.24413139z"
                        />
                        <path
                            d="m5.55492214 4.4374751c-.08320198.45575682.22778809.89521257.69111084.97657367.46332275.08136111.91046597-.22494518.99366795-.680702s-.22778809-.89520295-.69111084-.97656886c-.46327372-.08136111-.91046598.22494998-.99366795.68069719zm1.05299287.18488974c-.02078824.11396325-.13257404.19051578-.24842924.1701755-.11580618-.02034028-.19356595-.13016815-.17277771-.2441314.02083726-.11391516.13262307-.19051577.24842924-.1701755.11580617.02034028.19356595.13021624.17277771.2441314z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconSale;
