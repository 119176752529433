import Column from "@Containers/components/Column";
import Row from "@Containers/components/Row";
import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import useIsMobile from "@Hooks/selector/useIsMobile";
import RouteTransferType from "@Route/features/transportType/types/RouteTransfer.type";
import TicketPaymentType from "@MyTicket/types/TicketPayment.type";
import TicketPrice from "@MyTickets/components/TicketPrice";
import PassengersNumber from "@MyTicket/features/ticketDetails/components/PassengersNumber";

interface TicketPaymentSectionProps {
    isReturn: boolean,
    isIntermodal: boolean,
    isCancelled,
    numberOfPassengers: number,
    routeTransferTypeArray: RouteTransferType[],
    payment: TicketPaymentType,
}

function TicketPaymentSection(props: TicketPaymentSectionProps) {
    const {
        isReturn,
        isIntermodal,
        isCancelled,
        numberOfPassengers,
        routeTransferTypeArray,
        payment,
    } = props

    const {
        creditCardCompany,
        priceInCurrency,
    } = payment

    const {
        outbound_price: outboundTicketPriceLabel,
        return_price: returnTicketPriceLabel,
        trip_price: tripPriceLabel,
    } = useTranslation('my_profile.my_tickets')

    const payedWithLabel = useTranslation('my_profile.my_tickets.payed_with', {creditCardCompany})

    const paymentTitle = () => {
        if (isReturn) {
            return returnTicketPriceLabel
        }
        if (isIntermodal) {
            return tripPriceLabel
        }
        return outboundTicketPriceLabel
    }

    const isMobile = useIsMobile()

    return (
        <Row justify className="payment-section">
            <Column>
                {!isMobile && (
                    <div className="gb--emphasize-info payment-title">
                        {paymentTitle()}
                    </div>
                )}
                {creditCardCompany && (
                    <div>{payedWithLabel}</div>
                )}
            </Column>
            <TicketPrice
                isIntermodal={isIntermodal}
                isCancelled={isCancelled}
                routeTransferTypeArray={routeTransferTypeArray}
                priceInCurrency={priceInCurrency}
            >
                <PassengersNumber
                    numberOfPassengers={numberOfPassengers}
                />
            </TicketPrice>
        </Row>
    );
}

export default TicketPaymentSection;