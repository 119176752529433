import React, {ReactNode} from 'react'
import Row from "@Containers/components/Row";

interface HorizontalCardWithActionProps {
    title: string,
    content: string,
    icon: ReactNode,
    link: string,
    actionText: string,
    className?: string,
    btnClass?: string
}

function HorizontalCardWithLink({title, content, icon, link, actionText, className = "", btnClass = ""}: HorizontalCardWithActionProps) {
    return (
        <Row center className={className}>
            {icon}
            <Row center gap justify wrap className="content-container">
                <div className="card-content">
                    <h5>{title}</h5>
                    <p>{content}</p>
                </div>
                <a
                    className={`btn btn--green ${btnClass}`}
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {actionText}
                </a>
            </Row>
        </Row>
    )
}

export default HorizontalCardWithLink