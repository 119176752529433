import React, {ReactNode} from "react";
import Column from "@Containers/components/Column";
import Notification from "@Features/notification/components/Notification";

interface CookieSectionProps {
    title: string,
    subtitle?: string
    children: ReactNode,
}

function CookieSection(props: CookieSectionProps) {
    if (!props.children) {
        return null;
    }

    return (
        <Column>
            <h5>{props.title}</h5>
            {props?.subtitle && (
                <small>
                    {props.subtitle}
                </small>
            )}
            <div className="gb--emphasize gb--emphasize-info">
                {props.children}
            </div>
        </Column>
    );
}

export default CookieSection;