/**
 * Sets cookie for 30 years
 * @param setCookie {function}
 * @param cookie {string}
 * @param value {*}
 */
export default function setCookieForThirtyYears(setCookie, cookie, value) {
    const expires = new Date()
    expires.setFullYear(expires.getFullYear() + 30)
    const path = "/"

    setCookie(cookie, value, {expires, path})
}