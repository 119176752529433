import React from "react";
import {useTranslation} from "@Translation/hooks/useTranslation";
import CookieType from "@Cookies/types/Cookie.type";
import Modal from "@Generic/Modal/Modal";
import CookieSection from "@Cookies/components/CookieSection";
import List from "@Containers/components/List";
import CookieCompanySection from "@Cookies/components/CookieCompanySection";
import {isNotEmpty} from "@Array/isNotEmpty";

interface CookieModalProps {
    closeModal: () => void,
    cookie: CookieType
}

function CookieDescriptionModal(props: CookieModalProps) {
    const {
        closeModal,
        cookie,
    } = props

    const modal = () => (
        <div className="gb--cookie-description-modal">
            <CookieSection title="Description of service">
                {cookie.description}
            </CookieSection>

            <CookieCompanySection
                company={cookie.company}
            />

            {isNotEmpty(cookie.dataPurpose) && (
                <CookieSection title="Data purposes">
                    <List
                        containerClassName="gb--cookie-feature-list"
                        list={cookie.dataPurpose}
                    />
                </CookieSection>
            )}

            {isNotEmpty(cookie.dataCollected) && (
                <CookieSection title="Data collected">
                    <List
                        containerClassName="gb--cookie-feature-list"
                        list={cookie.dataCollected}
                    />
                </CookieSection>
            )}

            <CookieSection
                title="Legal basis"
                subtitle="In the following the required legal basis for the processing of data is listed."
            >
                {cookie.legalBasis}
            </CookieSection>

            <CookieSection
                title="Location of processing"
                subtitle="This is the primary location where the collected data is being processed. If the data is also processed in other countries, you are informed separately"
            >
                {cookie.processingLocation}
            </CookieSection>

            <CookieSection
                title="Retention period"
                subtitle="The retention period is the time span the collected data is saved for the processing purposes. The data needs to be deleted as soon as it is no longer needed for the stated processing purposes."
            >
                {cookie.retentionPeriod}
            </CookieSection>

            {isNotEmpty(cookie.thirdCountryTransfer) && (
                <CookieSection
                    title="Transfer to third countries"
                    subtitle="This service may forward the collected data to a different country. Please note that this service might transfer the data to a country without the required data protection standards. If the data is transferred to the USA, there is a risk that your data can be processed by US authorities, for control and surveillance measures, possibly without legal remedies. Below you can find a list of countries to which the data is being transferred. For more information regarding safeguards please refer to the website provider’s privacy policy or contact the website provider directly."
                >
                    <List
                        containerClassName="gb--cookie-feature-list"
                        list={cookie.thirdCountryTransfer}
                    />
                </CookieSection>
            )}

            {isNotEmpty(cookie.dataRecipients) && (
                <CookieSection title="Data recipients">
                    {cookie.dataRecipients.map((company) => (
                        <CookieCompanySection
                            company={company}
                        />
                    ))}
                </CookieSection>
            )}
        </div>
    )

    const closeLabel = useTranslation("my_tickets.close")
    return (
        <Modal
            closeAction={closeModal}
            buttonText={closeLabel}
            handleClick={closeModal}
            message={modal()}
            title={cookie.name}
        />
    );
}

export default CookieDescriptionModal;