import React from 'react'
import useCreateCompanySpecificMenuItems from "@Header/hooks/useCreateCompanySpecificMenuItems";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import TransportSpecificLinkMobile from "@Header/components/TransportSpecificLinks/TransportSpecificLinkMobile";

function MobileAdditionalMenuItems() {
    const {company_id: companyId} = useCompanyStyle()
    const urlsForHeaderMenu = useAppSelector((state) => state?.page.urlsForHeaderMenu)

    const companySpecificMenuItems = useCreateCompanySpecificMenuItems({urlsForHeaderMenu, companyId})

    return (
        <>
            {companySpecificMenuItems.map((item) => (
                <TransportSpecificLinkMobile
                    title={item.titleKey}
                    items={item.list}
                    tag={item.key}
                    key={item.key}
                />
            ))}
        </>
    )
}

export default MobileAdditionalMenuItems