import Translation from "@Translation/components/Translation";
import React from "react";
import useAcceptCookies from "@Cookies/hooks/useAcceptCookies";

type CookieAcceptAllButtonProps = {}

function CookieAcceptAllButton(props: CookieAcceptAllButtonProps) {
    const {acceptAllCookies, acceptEssentialCookies} = useAcceptCookies()
    return (
        <button
            type="button"
            className="btn btn--green"
            onClick={acceptAllCookies}
        >
            <Translation translationKey="cookie.notice.accept_all"/>
        </button>
    );
}

export default CookieAcceptAllButton;