import React from 'react';
import CardThumbnailLeft from "@Generic/Cards/CardThumbnailLeft";
import IconLaptopDeals from "@Icons/FilledOutline/IconLaptopDeals";
import IconHeadset from "@Icons/FilledOutline/IconHeadset";
import IconMobilePhoneInHand from "@Icons/FilledOutline/IconMobilePhoneInHand";
import Translation from "@Translation/components/Translation";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import IntroDataType from "@Pages/homepage/types/IntroData.type";

function IntroPlanTrip() {
    const transportTypeKeyword = useAppSelector((state) => state?.searchResults.transportTypeKeyword)
    const {
        companyMainTransportType,
        urlsObject,
    } = useAppSelector((state) => state?.page)
    const heading = useTranslation(
        "home_page.plan_a_trip",
        {transportType: transportTypeKeyword}
    )

    const displayUrl = () => {
        if (companyMainTransportType !== 'ferry') {
            return urlsObject.specialOffer.url
        }
        return null
    }
    const {
        travel_deals_title: travelDealsTitle,
        travel_deals_description: travelDealsDescription,
        help_title: helpTitle,
        help_description: helpDescription,
        app_title: appTitle,
        app_description: appDescription,
    } = useTranslation("home_page")

    const introData: IntroDataType[] = [
        {
            cardIcon: <IconLaptopDeals/>,
            cardPath: displayUrl(),
            cardColor: 'orange',
            cardHeading: travelDealsTitle,
            cardDescription: travelDealsDescription,
            cardId: "travelDeals",
        },
        {
            cardIcon: <IconHeadset/>,
            cardPath: urlsObject.helpCenter.url,
            cardColor: 'green',
            cardHeading: helpTitle,
            cardDescription: helpDescription,
            cardId: "help",
        },
        {
            cardIcon: <IconMobilePhoneInHand/>,
            cardPath: urlsObject.mobileAppPage?.url,
            cardColor: 'blue',
            cardHeading: appTitle,
            cardDescription: appDescription,
            cardId: "mobile-app",
        },
    ]

    return (
        <section className="plan-trip gb--column">
            <h2>{heading}</h2>
            <h3>
                <Translation translationKey="home_page.save_time"/>
            </h3>
            <div className="cards__plan-trip gb--row gb--row-middle">
                {introData.map((item) => (
                    <CardThumbnailLeft
                        key={item.cardColor}
                        icon={item.cardIcon}
                        path={item.cardPath}
                        cardColorClass={item.cardColor}
                        cardHeading={item.cardHeading}
                        cardDescription={item.cardDescription}
                        cardId={item.cardId}
                    />
                ))}
            </div>
            <p>
                <Translation translationKey="home_page.you_can_find"/>
            </p>
        </section>
    );
}

export default IntroPlanTrip;