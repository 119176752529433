import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {isEmpty} from "@Array/isEmpty";
import RouteType from "@Route/types/Route.type";
import CheckOrderPurchasedRouteType from "@CheckOrder/types/CheckOrderPurchasedRoute.type";

/**
 * Returns purchased route as composite if intermodal, otherwise regular route
 */
export default function usePurchasedRoute(): CheckOrderPurchasedRouteType {
    const {
        purchasedRoute,
        ticket,
    } = useAppSelector((state) => state.checkOrder)

    const getPurchasedRoute = (): RouteType => {
        if (ticket.isIntermodal || !isEmpty(purchasedRoute.singleRoutes)) {
            return ({
                ...purchasedRoute.singleRoutes[0],
                travelInfo: {
                    ...purchasedRoute.singleRoutes[0].travelInfo,
                    destinationStation: purchasedRoute.singleRoutes[purchasedRoute.singleRoutes.length - 1].travelInfo.destinationStation,
                },
            })
        }
        return purchasedRoute.singleRoute
    }

    return {
        ...purchasedRoute,
        singleRoute: getPurchasedRoute(),
    }
}