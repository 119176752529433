import Row from "@Containers/components/Row";
import Link from "@Generic/Link";
import Translation from "@Translation/components/Translation";
import React from "react";
import useLocale from "@Hooks/selector/useLocale";
import useCookiePreferences from "@Cookies/hooks/useCookiePreferences";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

type CookieLinksProps = {}

function CookieLinks(props: CookieLinksProps) {
    const locale = useLocale()
    const {toggleCookiePreferences, cookiePreferencesActive} = useCookiePreferences()
    const {
        siteUrl,
    } = useAppSelector((state) => state.page)

    return (
        <Row center className="gb--cookie-dialog-links">
            <Link href={`${siteUrl}/${locale}/privacy-policy`}>
                <Translation translationKey="cookie.preferences.privacy_policy"/>
            </Link>
            {!cookiePreferencesActive && (
                <button
                    type="button"
                    className="btn--blue-outline"
                    onClick={toggleCookiePreferences}
                >
                    <Translation translationKey="cookie.notice.open_preferences"/>
                </button>
            )}
        </Row>
    );
}

export default CookieLinks;