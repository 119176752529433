import Row from "@Containers/components/Row";
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import BookingFeePerPax
    from "@CheckOrder/features/paymentSummary/features/bookingFeeSummary/components/BookingFeePerPax";

type BookingFeeProps = {
    bookingFeeLabel: string,
    bookingFee: string,
}

function BookingFee({bookingFeeLabel, bookingFee}: BookingFeeProps) {
    const {passengers} = useAppSelector((state) => state?.checkOrder);
    const {shouldDisplayPriceInKn, priceInKn} = useFixedEurConversion(
        bookingFee,
        true,
        false
    )

    return (
        <BookingFeePerPax
            paxCount={passengers.length}
            bookingFeeLabel={bookingFeeLabel}
            bookingFee={bookingFee}
        />
    );
}

export default BookingFee;