import React from 'react';
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import {PagesType} from "@/types/pageSlice/Pages.type";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import MenuItem from "@Header/components/TopBar/MenuItem";
import {localeSpecificMenuItems} from "@Header/constants/localeSpecificMenuItems";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import useCreateCompanySpecificMenuItems from "@Header/hooks/useCreateCompanySpecificMenuItems";
import TransportSpecificLinksTitle from "@Header/components/TransportSpecificLinks/TransportSpecificLinksTitle";

function LocaleSpecificMainMenu() {
    const {currentRoute} = window
    const urlsObject = useAppSelector((state) => state?.page.urlsObject)
    const urlsForHeaderMenu = useAppSelector((state) => state?.page.urlsForHeaderMenu)
    const visibleMenuList = [];
    const {company_id: companyId} = useCompanyStyle()

    localeSpecificMenuItems.map((item) => {
        const menuItem = Object.entries(urlsObject).find((entry : [PagesType, UrlObjectType]) => {
            const [key, urlObject] = entry
            return key === item
        })

        if (menuItem && menuItem[1].text !== "") {
            visibleMenuList.push(menuItem)
        }
    })

    const companySpecificMenuItems = useCreateCompanySpecificMenuItems({urlsForHeaderMenu, companyId})

    return (
        <>
            {companySpecificMenuItems.map((item) => (
                <TransportSpecificLinksTitle
                    title={item.titleKey}
                    items={item.list}
                    key={item.key}
                />
            ))}
            {visibleMenuList.map((entry) => (
                <MenuItem
                    entry={entry}
                    currentRoute={currentRoute}
                    visible
                    key={entry[0]}
                />
            ))}
        </>
    )
}

export default LocaleSpecificMainMenu