import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import {getTodayServerDate} from "@DateTime/functions/getTodayServerDate";
import useDatePickerLocale from "@Features/datePicker/hooks/useDatePickerLocale";
import {dateFormat as standardDateFormat} from "@Form/features/dates/constants/dateFormat";
import handleMobileKeyboardOnFocus from "@Features/datePicker/functions/handleMobileKeyboardOnFocus";

function DatePickerWrapper(props: ReactDatePickerProps) {
    const {
        className = 'search-datepicker__outbound gb--input-standalone',
        calendarClassName = "react-search-datepicker",
        dateFormat = standardDateFormat,
        minDate,
        onFocus: customOnFocus = (_e) => {},
        ...rest
    } = props

    const locale = useDatePickerLocale()
    const today = getTodayServerDate()

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        customOnFocus(e)
        handleMobileKeyboardOnFocus(e)
    }

    return (
        <DatePicker
            className={className}
            calendarClassName={calendarClassName}
            dateFormat={dateFormat}
            minDate={minDate || today}
            locale={locale}
            onFocus={onFocus}
            {...rest}
        />
    )
}

export default DatePickerWrapper