import React, {ReactNode} from "react";

interface LanguageProps {
    children: ReactNode,
    linkActiveLanguage: string
}

const Language = ({children, linkActiveLanguage}: LanguageProps) => {
    const className = "gb--row gb--row-middle gb--row-justify"

    if (linkActiveLanguage) {
        return (
            <a href={decodeURIComponent(linkActiveLanguage)} className={className}>
                {children}
            </a>
        )
    }
    return (
        <span className={className}>
                {children}
        </span>
    )
}

export default Language