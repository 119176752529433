import Form from "@Form/components/Form";
import LocationToLocationBackgroundImage from "@Form/components/LocationToLocationBackgroundImage";
import OurValuesCta from "@Pages/stationToStation/features/header/components/OurValuesCta";
import getCountryLocationImagePaths from "@Form/functions/getCountryLocationImagePaths";

function StsPageHeader() {
    const imagePaths = getCountryLocationImagePaths()
    if (!imagePaths) {
        return (
            <Form formVersion="1"/>
        )
    }

    return (
        <section className="gb--search-2">
            <LocationToLocationBackgroundImage imagePaths={imagePaths}/>
            <div className="gb--search-2-wrapper">
                <Form formVersion="2"/>
            </div>
            <OurValuesCta/>
        </section>
    )
}

export default StsPageHeader