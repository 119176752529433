interface ListProps {
    containerClassName?: string,
    itemClassName?: string
    list: string[],
}

const List = (props: ListProps) => (
    <ul className={props.containerClassName}>
        {props.list.map((item) => (
            <li className={props.itemClassName}>
                {item}
            </li>
        ))}
    </ul>
);

export default List;