import RouteItinerary from "@Route/components/Sections/RouteItinerary";
import Row from "@Containers/components/Row";
import React from "react";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import {createRoute} from "@MyTicket/features/ticketDetails/functions/createRoute";
import TicketCompanyData from "@MyTicket/features/ticketDetails/components/TicketCompanyData";
import PassengersList from "@MyTicket/features/ticketDetails/components/PassengersList";

interface TicketTravelDetailsProps {
    id: string,
    route: TicketRouteType,
    isIntermodal?: boolean,
}

function TicketTravelDetails(props: TicketTravelDetailsProps) {
    const {
        id,
        route,
        isIntermodal = false,
    } = props

    const {
        companyLetterCode,
        companyId,
        companyName,
        passengers,
        logoUrl,
    } = route

    const routeDetails = createRoute({id, route})

    return (
        <Row justify gap className="route-expanded-details-section">
            <RouteItinerary
                companyLetterCode={companyLetterCode ?? ""}
                route={routeDetails}
            />
            <TicketCompanyData
                companyName={companyName}
                companyId={companyId}
                logoUrl={logoUrl}
            />
            <PassengersList
                passengers={passengers}
                transportTypeId={isIntermodal && route.transportTypeId}
            />
        </Row>
    );
}

export default TicketTravelDetails