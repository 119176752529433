import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useIsLatestCookiePolicyAccepted from "@Cookies/hooks/useIsLatestCookiePolicyAccepted";
import useAcceptCookies from "@Cookies/hooks/useAcceptCookies";
import {useDispatch} from "react-redux";
import {toggleCookiePreferences as dispatchToggle} from "@Cookies/reducers/cookiesSlice";

export default function useCookiePreferences() {
    const {cookiePreferencesActive} = useAppSelector((state) => state?.cookies)
    const latestCookiePolicyAccepted = useIsLatestCookiePolicyAccepted()
    const {acceptAllCookies} = useAcceptCookies()

    const dispatch = useDispatch()
    const toggleCookiePreferences = () => dispatch(dispatchToggle())

    return {
        cookiePreferencesActive,
        latestCookiePolicyAccepted,
        acceptAllCookies,
        toggleCookiePreferences,
    }
}