import React from 'react';
import PartnersSection from "./PartnersSection";
import CountriesSection from "./CountriesSection";
import useIsCompanyStore from "@Hooks/selector/useIsCompanyStore";
import TravelBenefits from "./TravelBenefits";
import IntroPlanTrip from "./IntroPlanTrip";
import TravelConnections from "./TravelConnections";
import InfoCardsSection from "./InfoCardsSection";
import useIsFerry from "@GetByFerry/hooks/useIsFerry";
import PopularLines from "@Pages/homepage/components/PopularLines";
import {useTranslation} from "@Translation/hooks/useTranslation";
import {useSelector} from "react-redux";
import Form from "@Form/components/Form";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

function Homepage() {
    const isCompanyStore = useIsCompanyStore()
    const isFerry = useIsFerry()
    const {
        popularLines: mostPopularLines,
        newlyAddedLines,
        popularBusLines,
        newlyAddedBusLines,
        popularBusLinesCountry,
    } = useAppSelector((state) => state?.page.homepageData);

    const newlyAddedLinesTitle = useTranslation('home_page.newly_added_ferry_lines');
    const newlyAddedBusLinesTitle = useTranslation('home_page.new_added_lines');
    const mostPopularLinesTitle = useTranslation('home_page.most_popular_ferry_lines');
    const popularBusLinesTitle = useTranslation('home_page.most_popular_routes');
    const popularBusLinesCountryTitle = useTranslation('home_page.most_popular_routes_country');

    if (!isCompanyStore) {
        return (
            <div className="homepage">
                <Form/>
                <div className="gb--main-content">
                    <IntroPlanTrip/>
                    <PartnersSection/>
                    <TravelBenefits/>
                    <CountriesSection/>
                    <TravelConnections/>
                    {isFerry ? (
                        <>
                            <PopularLines title={mostPopularLinesTitle} lines={mostPopularLines}/>
                            <PopularLines title={newlyAddedLinesTitle} lines={newlyAddedLines}/>
                        </>
                    ) : (
                        <>
                            <PopularLines title={popularBusLinesTitle} lines={popularBusLines}/>
                            <PopularLines backgroundBlue title={popularBusLinesCountryTitle} lines={popularBusLinesCountry}/>
                            <PopularLines title={newlyAddedBusLinesTitle} lines={newlyAddedBusLines}/>
                        </>
                    )}
                </div>
            </div>
        );
    }

    return (
        <Form/>
    )
}

export default Homepage;