import Row from "@Containers/components/Row";
import React from "react";
import CookieAcceptEssentialButton from "@Cookies/components/CookieAcceptEssentialButton";
import CookieAcceptAllButton from "@Cookies/components/CookieAcceptAllButton";

type CookieActionsProps = {}

function CookieActionButtons(props: CookieActionsProps) {
    return (
        <div className="gb--cookie-dialog-ctas">
            <Row>
                <CookieAcceptEssentialButton/>
                <CookieAcceptAllButton/>
            </Row>
        </div>
    );
}

export default CookieActionButtons;