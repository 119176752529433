import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {IconColoursType} from "@Features/icons/types/IconColoursType";
import setIconColour from "@Features/icons/functions/setIconColour";

type IconColorChangeProp = IconType & {
    fillColour?: IconColoursType,
}

function IconPencilContact(props: IconColorChangeProp) {
    const {
        width = '16',
        height = '16',
        viewBox = '0 0 16 16',
        fillColour = "navy",
        outline = '#070c63',
        title = 'IconPencilContact',
        ...rest
    } = props

    const fill = setIconColour(fillColour as IconColoursType)

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            outline={outline}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(2 1)">
                    <path
                        d="m5.87678808 2.02578773 1.72634456-.01482374-.09056079 10.52283791-1.72634457.0148237z"
                        fill={fill}
                        fillRule="nonzero"
                        transform="matrix(.75156931 .65965413 -.65965413 .75156931 6.465319 -2.607661)"/>
                    <path
                        d="m7.49880515 2.3429454c.55354466-.61992715 1.51364575-.68144664 2.14441077-.13739682l1.08097918.93232438c.6307651.54404463.6933447 1.48765165.1398001 2.10757361l-6.62794814 7.42254463-3.5399004 1.2991661c-.38525081.1413966-.77554393-.1958359-.68214483-.5894817l.85718291-3.61252323zm1.53431185.54585753c-.24683029-.21288974-.62251972-.18881802-.83911726.05376397l-6.24609584 6.99494185 1.97476912 1.70325295 6.24607468-6.99492625c.2166504-.24258719.1921259-.61182359-.0547043-.82471333zm-5.95437099 9.23354887-1.50840259-1.3010361-.48204845 2.0315508z"
                        fill={outline}
                    />
                    <path
                        d="m9.92929108 1.04707753 2.25920722-.01939899c.4157295-.00356972.7556386.33055176.7592083.7462812.000037.00431381.000037.00862786-.0000001.01294166l-.0001615.0187667c-.0035684.41463552-.3388253.74988597-.7534609.7534463l-2.25920723.01939899c-.41572945.00356972-.75563859-.33055176-.7592083-.74628121-.00003704-.0043138-.000037-.00862785.00000012-.01294166l.00016151-.01876669c.00356835-.41463552.33882529-.74988597.75346088-.7534463z"
                        fill={fill}
                        fillRule="nonzero"
                        transform="matrix(.75160961 .65960822 -.65960822 .75160961 3.93696 -6.848515)"/>
                </g>
            )}
        />

    );
}

export default IconPencilContact;
