import React from "react";
import CompanyType from "@Cookies/types/Company.type";
import CookieSection from "@Cookies/components/CookieSection";
import Link from "@Generic/Link";
import Column from "@Containers/components/Column";

interface CookieCompanySectionProps {
    company: CompanyType
}

function CookieCompanySection(props: CookieCompanySectionProps) {
    return (
        <CookieSection title="Processing company">
            <span>
                {props.company.name}
            </span>
            <span>
                {props.company.address}
            </span>
            {props.company.privacyPolicyUrl && (
                <Column>
                    <h5>
                        Privacy policy
                    </h5>
                    <Link underline href={props.company.privacyPolicyUrl}>
                        {props.company.privacyPolicyUrl}
                    </Link>
                </Column>
            )}
            {props.company.cookiePolicyUrl && (
                <>
                    <h5>
                        Cookie policy
                    </h5>
                    <Link underline href={props.company.cookiePolicyUrl}>
                        {props.company.cookiePolicyUrl}
                    </Link>
                </>
            )}
        </CookieSection>
    );
}

export default CookieCompanySection;