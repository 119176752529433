import {useDispatch} from "react-redux";
import {setCookieState} from "@Cookies/reducers/cookiesSlice";
import useSetCookieByKey from "@Cookies/functions/useSetCookieByKey";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import React from "react";

export default function useSetCookie(cookie: string, canBeToggled = true) {
    const dispatch = useDispatch()
    const {setCookieByKey} = useSetCookieByKey()
    const setReduxState = (cookie: string, value: boolean) => dispatch(setCookieState({[cookie]: value}))
    const isActive = useAppSelector((state) => state?.cookies?.[cookie]) ?? false

    /**
     * @param cookie {string}
     * @param value {any}
     */
    const set = (cookie: string, value: boolean) => {
        setReduxState(cookie, value)
        setCookieByKey(cookie, value)
    }

    const toggleCookie = (e: React.MouseEvent<HTMLLabelElement>) => {
        e.stopPropagation()
        if (canBeToggled) {
            set(cookie, !isActive)
        }
    }

    return {
        isActive,
        setCookie: set,
        setReduxState,
        toggleCookie,
    }
}