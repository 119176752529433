import React from 'react'
import {useToggle} from "@Hooks/dispatch/useToggle";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import TransportSpecificLinksDropdown from "@Header/components/TransportSpecificLinks/TransportSpecificLinksDropdown";
import Translation from "@Translation/components/Translation";

interface TransportListDropdownProps {
    title: string,
    items: UrlObjectType[]
}

function TransportSpecificLinksTitle({title, items}: TransportListDropdownProps) {
    const showTransportListDropdown = useToggle()

    return (
        <li
            className="gb--menu__item"
            onMouseEnter={() => showTransportListDropdown.set(true)}
            onMouseLeave={() => showTransportListDropdown.set(false)}
        >
            <Translation translationKey={title}/>

            {showTransportListDropdown.value && (
                <TransportSpecificLinksDropdown
                    items={items}
                />
            )}
        </li>
    )
}

export default TransportSpecificLinksTitle