/**
 * Generic array to object conversion where we require objects (e.g. api company response)
 * Added any as return value to enable casting the type on function return
 */
export const convertArrayToObject = (array: any[], key: string | undefined = undefined): Record<any, any> => (
    array.reduce((obj, item) => {
        if (key) {
            return {
                ...obj,
                [item[key]]: item,
            };
        }
        return {
            ...obj,
            ...item,
        };
    }, {})
)