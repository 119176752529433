import {setCookieState} from "@Cookies/reducers/cookiesSlice";
import getActiveCookiesFromCookieData from "@Cookies/functions/getActiveCookiesFromCookieData";
import {useCookies} from "react-cookie";
import setCookieForThirtyYears from "@Cookies/functions/setCookieForThirtyYears";
import getCookie from "@Cookies/functions/getCookie";
import useSetCookieByKey from "@Cookies/functions/useSetCookieByKey";
import {useAppDispatch} from "@Hooks/dispatch/useAppDispatch";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

/**
 * Handles combined cookies interaction
 */
export default function useAcceptCookies() {
    const dispatch = useAppDispatch()
    const {cookieVersion} = useAppSelector((state) => state.cookies)
    const [cookies, setCookie, removeCookie] = useCookies([]);
    const {setCookieByKey, updateWindowValue} = useSetCookieByKey()
    const setReduxState = (cookie: string, value: boolean) => dispatch(setCookieState({[cookie]: value}))

    const {
        keysThatCanBeToggled,
    } = getActiveCookiesFromCookieData()

    const acceptEssentialCookies = () => {
        setCookieForThirtyYears(setCookie, "cookiesConsent", cookieVersion)
        dispatch(setCookieState({cookiesConsent: true}))
        dispatch(setCookieState({cookiePreferencesActive: false}))
        window.updateConsent()
    }

    const acceptAllCookies = () => {
        keysThatCanBeToggled.map(({cookieKey}) => {
            const keyExists = getCookie(cookieKey, false)

            if (!keyExists) {
                setReduxState(cookieKey, true)
                setCookieByKey(cookieKey, true)
                updateWindowValue(cookieKey, true)
            }
        })

        acceptEssentialCookies()
    }

    return {
        acceptAllCookies,
        acceptEssentialCookies,
    }
}