import React from 'react';
import GenericSvgIcon from "@Icons/GenericSvgIcon";
import IconType from "@/types/Icon.type";

function IconWorkWithUs(props: IconType) {
    const {
        width = '98',
        height = '90',
        fill = '',
        viewBox = '0 0 98 90',
        title = 'IconWorkWithUs',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={{title}}
            {...rest}
            svgIconPath={(
                <g>
                    <g
                        clipRule="evenodd"
                        fillRule="evenodd"
                    >
                        <path
                            d="m53.5059 10.5527c0-1.24261 1.0073-2.24997 2.25-2.24997h12.453c1.6977 0 3.3258.6744 4.5263 1.87487 1.2004 1.2004 1.8748 2.8285 1.8748 4.5262v33.2082c0 1.2426-1.0074 2.25-2.25 2.25-1.2427 0-2.25-1.0074-2.25-2.25v-33.2082c0-.5042-.2003-.9878-.5568-1.3443s-.8401-.5568-1.3443-.5568h-12.453c-1.2427 0-2.25-1.0073-2.25-2.25z"
                            fill="#070c63"
                        />
                        <path
                            d="m18.401 12.8027c-.5042 0-.9877.2003-1.3442.5568s-.5568.8401-.5568 1.3443v58.1144c0 .5042.2003.9877.5568 1.3442s.84.5568 1.3442.5568h24.9062c1.2426 0 2.25 1.0074 2.25 2.25s-1.0074 2.25-2.25 2.25h-24.9062c-1.6976 0-3.3258-.6744-4.5262-1.8748s-1.8748-2.8286-1.8748-4.5262v-58.1144c0-1.6977.6744-3.3258 1.8748-4.5262 1.2004-1.20047 2.8286-1.87487 4.5262-1.87487h12.4531c1.2427 0 2.25 1.00736 2.25 2.24997 0 1.2427-1.0073 2.25-2.25 2.25z"
                            fill="#070c63"
                        />
                        <path
                            d="m30.4744 1.87482c1.2005-1.200428 2.8286-1.87482 4.5262-1.87482h16.6042c1.6976 0 3.3257.674392 4.5262 1.87482 1.2004 1.20042 1.8748 2.82855 1.8748 4.52621v12.45307c0 1.2427-1.0074 2.25-2.25 2.25h-24.9062c-1.2426 0-2.25-1.0073-2.25-2.25v-12.45307c0-1.69766.6744-3.32579 1.8748-4.52621zm4.5262 2.62518c-.5041 0-.9877.20029-1.3442.5568s-.5568.84005-.5568 1.34423v10.20307h20.4062v-10.20307c0-.50418-.2003-.98772-.5568-1.34423s-.8401-.5568-1.3442-.5568z"
                            fill="#779ae9"
                        />
                    </g>
                    <path
                        d="m76.1756 79.2864c5.8375-5.8374 5.8375-15.3018 0-21.1392-5.8374-5.8375-15.3017-5.8375-21.1392 0-5.8374 5.8374-5.8374 15.3018 0 21.1392 5.8375 5.8375 15.3018 5.8375 21.1392 0z"
                        fill="#7ed321"
                    />
                    <path
                        d="m69.3828 62.1324c.9229-1.3187 2.4242-1.7695 3.4649-1.0408 1.0404.7291 1.1303 2.2949.2075 3.6136l-7.5249 10.7532c-.1714.4251-.4933.8303-.9459 1.1474-1.0405.7289-2.2819.6495-2.8648-.1834l-4.7999-6.8588c-.5829-.8329-.2329-2.0272.8076-2.7562s2.2819-.6495 2.8647.1833l2.6956 3.8514z"
                        fill="#fff"
                    />
                    <path
                        d="m55.1791 39.1145h-25.4043c-1.2703 0-2.2416-.9719-2.2416-2.2428s.9713-2.2428 2.2416-2.2428h25.4043c1.2702 0 2.2415.9719 2.2415 2.2428s-.9713 2.2428-2.2415 2.2428z"
                        fill="#4a79e2"
                        fillOpacity=".5"
                    />
                    <path
                        d="m43.9713 52.5696h-14.1965c-1.2703 0-2.2416-.9719-2.2416-2.2428s.9713-2.2428 2.2416-2.2428h14.1965c1.2702 0 2.2415.9719 2.2415 2.2428s-.9713 2.2428-2.2415 2.2428z"
                        fill="#4a79e2"
                        fillOpacity=".5"
                    />
                    <path
                        d="m55.9243 52.5696h-.7472c-1.2702 0-2.2416-.9719-2.2416-2.2428s.9714-2.2428 2.2416-2.2428h.7472c1.2702 0 2.2415.9719 2.2415 2.2428s-.9713 2.2428-2.2415 2.2428z"
                        fill="#4a79e2"
                        fillOpacity=".5"
                    />
                </g>
            )}
        />
    );
}

export default IconWorkWithUs;
