import React from 'react';
import CookieDetails from "@Cookies/components/CookieDetails";
import CookieType from "@Cookies/types/Cookie.type";

type CookiesListProps = {
    cookies: CookieType[]
    canBeToggled: boolean,
}

const CookiesList = ({cookies, canBeToggled}: CookiesListProps) => (
    <div className="gb--cookie-list">
        {cookies.map((cookie) => (
            <CookieDetails
                canBeToggled={canBeToggled}
                key={cookie.cookieKey}
                cookie={cookie}
            />
        ))}
    </div>
);

export default CookiesList;