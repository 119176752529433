import useGetAdditionalPassengerFieldByName from "@CheckOrder/hooks/useGetAdditionalPassengerFieldByName";
import {convertArrayToObject} from "@Array/convertArrayToObject";

export default function usePrepareAdditionalInfoForPassengerSubmit() {
    const {
        getAdditionalPassengerFieldByName,
    } = useGetAdditionalPassengerFieldByName()

    // for all the country fields, only send values (id-s for our routes/ full names for BawBi etc.)
    const prepareAdditionalInfo = (additionalInfo: object) => {
        const modifiedAdditionalInfo = Object.entries(additionalInfo).map(([fieldName, fieldValue]) => {
            const ticketField = getAdditionalPassengerFieldByName(fieldName)
            if (ticketField.type === "country") {
                return {[fieldName]: fieldValue.value}
            }
            return {[fieldName]: fieldValue}
        })

        return convertArrayToObject(modifiedAdditionalInfo)
    }

    return {
        prepareAdditionalInfo,
    }
}