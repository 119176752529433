import Row from "@Containers/components/Row";
import IconPaxWithLuggage from "@Icons/FilledOutline/IconPaxWithLuggage";
import Translation from "@Translation/components/Translation";
import Column from "@Containers/components/Column";
import React from "react";
import TicketRouteType from "@MyTicket/types/TicketRoute.type";
import useIntermodalTransportData from "@MyTicket/hooks/useIntermodalTransportData";

interface IntermodalTransportDataProps {
    baseRoutes: TicketRouteType[],
}

function IntermodalTransportData({baseRoutes}: IntermodalTransportDataProps) {
    const {
        usedTransportTypesDescription,
        selfTransferStations,
    } = useIntermodalTransportData(baseRoutes)

    return (
        <Column className="intermodal-transport-info">
            <div>
                {usedTransportTypesDescription}
            </div>
            <Row className="gb--label gb--label-blue info-label">
                <>
                    <IconPaxWithLuggage height="16px" width="20px"/>
                    <Translation translationKey="search_results.self_transfer"/>
                    &nbsp;
                    {selfTransferStations}
                </>
            </Row>
        </Column>
    );
}

export default IntermodalTransportData