import LocationToLocationCountryImageType
    from "@Pages/stationToStation/features/header/types/LocationToLocationCountryImage.type";

type LocationToLocationBackgroundImageProps = {
    imagePaths: LocationToLocationCountryImageType
}

function LocationToLocationBackgroundImage({imagePaths}: LocationToLocationBackgroundImageProps) {
    const {
        pathMobile,
        pathTablet,
        pathWindow,
    } = imagePaths

    return (
        <div className="gb--search-2-background">
            <picture>
                <source
                    media="(min-width: 680px)"
                    type="image/jpg"
                    srcSet={pathTablet}
                />
                <source
                    media="(min-width: 992px)"
                    type="image/jpg"
                    srcSet={pathWindow}
                />
                <img
                    typeof="image/jpg"
                    src={pathMobile}
                    alt="heading"
                />
            </picture>
            <div className="gb--search-2-background_overlay"/>
        </div>
    )
}

export default LocationToLocationBackgroundImage