import React from 'react';
import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "@/types/Icon.type";
import {IconColoursType} from "@Features/icons/types/IconColoursType";
import setIconColour from "@Features/icons/functions/setIconColour";

type IconColorChangeProp = IconType & {
    fillColour?: IconColoursType,
}

function IconBlog(props: IconColorChangeProp) {
    const {
        width = '16',
        height = '16',
        viewBox = '0 0 16 16',
        fillColour = "navy",
        outline = '#070c63',
        title = 'IconBlog',
        ...rest
    } = props

    const fill = setIconColour(fillColour as IconColoursType)

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            outline={outline}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(.5 1)">
                    <path
                        d="m8.07027349 5.70117297c-.36346813 0-.64514613.28708088-.64514613.6415693s.281678.6415693.64514613.6415693c.33197165 0 .61359372-.28708088.61359372-.6415693s-.28162207-.6415693-.61359372-.6415693zm-4.40561186 0c-.36346253 0-.64510697.28708088-.64510697.6415693s.28164444.6415693.64510697.6415693c.33199404 0 .61363288-.28708088.61363288-.6415693s-.28163884-.6415693-.61363288-.6415693z"
                        fill="#000"
                    />
                    <path
                        d="m3.96241235 8.03637116c0 3.10702034 2.470828 5.62579284 5.51876865 5.62579284.5395239 0 1.0609778-.0789273 1.5538444-.2260605 1.1742085-.3505535 2.1861234-1.0882726 2.893815-2.0683624.6732859-.9325851 1.0711596-2.08461546 1.0711596-3.33136994 0-3.10702031-2.4708784-5.62574724-5.518819-5.62574724-3.04794065 0-5.51876865 2.51872693-5.51876865 5.62574724z"
                        fill={fill}
                    />
                    <path
                        d="m13.7794699 13.8929008-.1060698-2.5251597c-.6589643.8676298-1.601229 1.5207189-2.6946543 1.8310103l2.6718291.7970286c.0662377.0197889.13186-.0326202.128895-.1028792z"
                        fill={fill}
                    />
                    <path
                        d="m5.86747875 5.70117297c-.36346813 0-.64514613.28708088-.64514613.6415693s.281678.6415693.64514613.6415693c.33197166 0 .61359372-.28708088.61359372-.6415693s-.28162206-.6415693-.61359372-.6415693z"
                        fill={outline}
                    />
                    <path
                        d="m11.5271088 6.12477979c0 3.10702031-2.47087837 5.62573581-5.51881902 5.62573581-.53952387 0-1.06092189-.0789273-1.55378847-.2260605-1.17421407-.3504964-2.18616259-1.0882156-2.89382054-2.06836237-.67332512-.93258513-1.07117083-2.08461548-1.07117083-3.33131294 0-3.10703741 2.47084479-5.62578145 5.51877984-5.62578145 3.04794065 0 5.51881902 2.51874404 5.51881902 5.62578145z"
                        fill="#fff"
                    />
                    <g fill={outline}>
                        <path
                            d="m6.00828978.99799669c-2.7775857 0-5.0292699 2.29532964-5.0292699 5.1267831 0 1.13674674.36236044 2.18578383.97590381 3.03550672.64545382.89397689 1.56805419 1.56611339 2.6371439 1.88530129.44861488.1339026.92374722.2059295 1.41622219.2059295 2.77761927 0 5.02930902-2.29533537 5.02930902-5.12673751 0-2.83145346-2.25168975-5.1267831-5.02930902-5.1267831zm-6.00828978 5.1267831c0-3.38262707 2.69001098-6.12477979 6.00828978-6.12477979 3.31831797 0 6.00832892 2.74215272 6.00832892 6.12477979 0 3.38258145-2.69001095 6.12473421-6.00832892 6.12473421-.53426514 0-1.0529219-.0712285-1.54701925-.2050741l-2.87699258.9027593c-.15183759.0476187-.31691154.0171655-.44275755-.0816647-.12585161-.0988301-.19682216-.2537763-.19035503-.4155658l.11358868-2.84332104c-.67132792-.98870106-1.06475405-2.1891485-1.06475405-3.48186787zm2.0013572 4.56386731-.04383212 1.0972261 1.0770953-.3380072c-.37105413-.2157954-.71726353-.4707123-1.03326318-.7592189z"
                        />
                        <path
                            d="m3.86330757 6.34268524c0 .30122391-.23951302.54536242-.53496444.54536242s-.53496444-.24413851-.53496444-.54536242c0-.30116688.23951302-.54530539.53496444-.54530539s.53496444.24413851.53496444.54530539z"
                        />
                        <path
                            d="m6.53813534 6.34268524c0 .30122391-.23949623.54536242-.53499241.54536242-.29544023 0-.53493646-.24413851-.53493646-.54536242 0-.30116688.23949623-.54530539.53493646-.54530539.29549618 0 .53499241.24413851.53499241.54530539z"
                        />
                        <path
                            d="m9.22277569 6.34268524c0 .30122391-.23949623.54536242-.53493646.54536242-.29549617 0-.53499241-.24413851-.53499241-.54536242 0-.30116688.23949624-.54530539.53499241-.54530539.29544023 0 .53493646.24413851.53493646.54530539z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconBlog;
