import GbtVehicles from "@Features/getByTransfer/components/GbtVehicles";
import GetByTransferBanner
    from "@SearchResults/features/banners/features/getByTransferBanner/components/GetByTransferBanner";
import React from "react";
import {TransfersDataType} from "@SearchResults/features/banners/features/getByTransferBanner/types/TransfersData.type";
import GetByTransferNoResultsNotification
    from "@SearchResults/features/banners/features/getByTransferBanner/components/GetByTransferNoResultsNotification";
import {useAppSelector} from "@Hooks/selector/useAppSelector";
import useExperiments from "@Hooks/selector/useExperiments";
import {
    defaultTransfersData
} from "@SearchResults/features/banners/features/getByTransferBanner/constants/defaultTransfersData";

interface GetByTransferSearchResultsBannerProps {
    transfersData: TransfersDataType,
    bannerType: false | "vehicle-snippets" | "banner",
    showNoResultsNotification: boolean,
    snippetsAdditionalClassName?: string,
}

function GetByTransferSearchResultsBanner(props: GetByTransferSearchResultsBannerProps) {
    const {gbtBannerTopOfSearchResult} = useExperiments();

    const {
        transfersData,
        bannerType,
        showNoResultsNotification,
        snippetsAdditionalClassName = "",
    } = props

    const {
        fromName,
        toName,
        vehicles,
    } = transfersData || {}
    
    if (!transfersData?.vehicles && gbtBannerTopOfSearchResult) {
        return (
            <GetByTransferBanner
                transfersData={defaultTransfersData}
            />
        );
    }

    return (
        <>
            {showNoResultsNotification && (
                <GetByTransferNoResultsNotification
                    fromName={fromName}
                    toName={toName}
                />
            )}
            {bannerType === "vehicle-snippets" ? (
                <GbtVehicles
                    vehicles={vehicles}
                    additionalClassName={snippetsAdditionalClassName}
                />
            ) : (
                <GetByTransferBanner
                    transfersData={transfersData}
                />
            )}
        </>
    )
}

export default GetByTransferSearchResultsBanner