/**
 * Uses Set to return only unique values from array
 */
export default function filterDuplicates<T>(array: T[], key: string): T[] {
    const seen = new Set()
    return array.filter((item) => {
        const duplicate = seen.has(item[key])
        seen.add(item[key])
        return !duplicate
    })
}