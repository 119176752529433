import React from 'react';
import Translation from "@Translation/components/Translation";
import LoaderButton from "@Generic/Loaders/LoaderButton";
import useFixedEurConversion from "@Currency/hooks/useFixedEurConversion";
import {useAppSelector} from "@Hooks/selector/useAppSelector";

interface ConfirmAndPayButtonProps {
    price: string,
    onClick: () => Promise<void>,
    loading: boolean,
}

function ConfirmAndPayButton(props: ConfirmAndPayButtonProps) {
    const {
        price,
        onClick,
        loading,
    } = props;

    const {shouldDisplayPriceInKn, priceInKn} = useFixedEurConversion(price, true, false)
    const priceString = shouldDisplayPriceInKn ? `${price} ${priceInKn}` : price

    const {priceDisplayCta} = useAppSelector((state) => state?.page.experimentsJson)

    return (
        <div className="gb--check-order-submit">
            <button
                type="submit"
                className="btn btn--green btn--mobile btn--confirm"
                onClick={!loading ? onClick : null}
            >
                {loading ? (
                    <LoaderButton/>
                ) : (
                    <span>
                    <Translation translationKey="t_summary.confirm_&_pay"/>
                        &nbsp;
                        {priceDisplayCta ? "" : priceString}
                    </span>
                )}
            </button>
        </div>
    );
}

export default ConfirmAndPayButton;