import rootExtractor from "@Dataset/rootExtractor";
import getCookie from "@Cookies/functions/getCookie";
import {convertArrayToObject} from "@Array/convertArrayToObject";

export default function getActiveCookiesFromCookieData() {
    const cookieData = JSON.parse(rootExtractor('cookieData'))

    const cookieKeys = cookieData.map(({cookieKey, cookies, canBeToggled}) => {
        const key = {
            cookieKey,
            active: getCookie(cookieKey) ?? true,
            canBeToggled,
        }
        const individualCookieKeys = cookies.map(({cookieKey}) => ({
            cookieKey,
            active: getCookie(cookieKey) ?? true,
            canBeToggled,
        }))

        return [key, ...individualCookieKeys]
    }).flat(Infinity)

    const keysThatCanBeToggled = cookieKeys.filter((key) => key.canBeToggled ?? key)
    const cookieKeysArray = cookieKeys.map((key) => ({[key.cookieKey]: key.active}))

    return {
        cookieData,
        keysThatCanBeToggled,
        cookieKeysArray,
        cookieKeys: convertArrayToObject(cookieKeysArray),
    }
}