import React from 'react';
import IconHome from "@Icons/IconHome";
import IconBusTab from "@Icons/IconBusTab";
import IconLocationArrow from "@Icons/IconLocationArrow";
import IconInfo from "@Icons/IconInfo";
import {DisplayedPagesType} from "@User/types/DisplayedPages.type";
import IconBusFilledTab from "@Icons/IconBusFilledTab";
import IconTrainFilledTab from "@Icons/IconTrainFilledTab";
import IconBlog from "@Icons/MenuIcons/IconBlog";
import IconPencilContact from "@Icons/MenuIcons/IconPencilContact";
import IconSale from "@Icons/MenuIcons/IconSale";
import IconStationLocator from "@Icons/MenuIcons/IconStationLocator";
import IconFerry from "@Icons/TransportTypeIcons/IconFerry";
import IconFlight from "@Icons/TransportTypeIcons/IconFlight";
import IconIslandPalm from "@Icons/MenuIcons/IconIslandPalm";

interface MobileMenuIconsProps {
    tag: DisplayedPagesType,
}

function MobileMenuIcons({tag}: MobileMenuIconsProps) {
    const components = {
        homePage: IconHome,
        rentBus: IconBusTab,
        contact: IconPencilContact,
        helpCenter: IconInfo,
        faq: IconInfo,
        specialOffer: IconSale,
        stationLocator: IconStationLocator,
        blog: IconBlog,
        availableRoutes: IconLocationArrow,
        buses: IconBusFilledTab,
        trains: IconTrainFilledTab,
        flights: IconFlight,
        ferries: IconFerry,
        croatian_islands: IconIslandPalm,
        asian_islands: IconIslandPalm,
    };

    const IconMenu = components[tag || ''];

    return (
        <IconMenu
            width="18"
            height="18"
            fill="#9b9b9b"
            style={{
                verticalAlign: 'text-top',
                marginRight: '1rem',
            }}
        />
    );
}

export default MobileMenuIcons;
