import useSelfRefreshingCookie from "@Cookies/hooks/useSelfRefreshingCookie";
import {useSelector} from "react-redux";

export default function useVisitorIdCookie() {
    const {
        cookiesConsent,
        uuid,
    } = useSelector((state) => state?.cookies)

    const {
        setSelfRefreshingCookie,
        removeCookie,
        cookies,
    } = useSelfRefreshingCookie("visitor_id", uuid, 30 * 24)

    return {
        uuid,
        cookiesConsent,
        setSelfRefreshingCookie,
        removeCookie,
        cookies,
    }
}