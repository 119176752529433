import {useCookies} from "react-cookie";
import getCookieExpiryTime from "@Cookies/functions/getCookieExpiryTime";

export default function useSelfRefreshingCookie(name: string, value: any, expireTime: number) {
    const expires = getCookieExpiryTime(expireTime)
    const [cookies, setCookie, removeCookie] = useCookies([name]);

    const setSelfRefreshingCookie = () => {
        setCookie(name, value, {expires})
    }

    return {
        cookies,
        removeCookie,
        setSelfRefreshingCookie,
    }
}