import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Row from "@Containers/components/Row";
import Column from "@Containers/components/Column";
import IconChevron from "@Icons/IconChevron";
import ToggleButton from "@Generic/ToggleButton";
import {useToggle} from "@Hooks/dispatch/useToggle";
import CookiesList from "@Cookies/components/CookiesList";
import {isEmpty} from "@Array/isEmpty";
import useSetCookie from "@Cookies/hooks/useSetCookie";
import {CookieCategoryType} from "@Cookies/types/CookieCategory.type";
import {useCookies} from "react-cookie";

type CookieTabProps = {
    cookieCategory: CookieCategoryType
}

function CookieTab({cookieCategory}: CookieTabProps) {
    const {
        name,
        description,
        cookieKey,
        cookies,
        canBeToggled,
    } = cookieCategory
    const cookieKeys = cookies.map((cookie) => cookie.cookieKey)

    const {
        value: expanded,
        onChange: toggleExpand,
    } = useToggle()

    const [storedCookies, setStoredCookie, removeCookie] = useCookies();
    const {
        setCookie,
        toggleCookie,
    } = useSetCookie(cookieKey, canBeToggled)

    const isActive = () => (cookieKeys.every((cookie) => (storedCookies[cookie] === true)))
    const toggleCategory = (e) => {
        e.stopPropagation()
        cookieKeys.map((cookie) => {
            setCookie(cookie, !isActive())
        })
    }

    const onClick = (e) => {
        e.stopPropagation()
        toggleExpand()
    }

    return (
        <div className="gb--cookie-tab">
            <div className="gb--cookie-tab-button" role="button" onClick={onClick}>
                <Row justify center className="gb--cookie-category">
                    <Column>
                        <h4>
                            {name}
                        </h4>
                        <span>
                            {description}
                        </span>
                    </Column>
                    <Row center>
                        <ToggleButton
                            disabled={!canBeToggled}
                            onClick={toggleCategory}
                            checked={canBeToggled ? isActive() : true}
                        />
                        {!isEmpty(cookies) && (
                            <IconChevron/>
                        )}
                    </Row>
                </Row>
            </div>
            {expanded && !isEmpty(cookies) && (
                <CookiesList
                    canBeToggled={canBeToggled}
                    cookies={cookies}
                />
            )}
        </div>
    );
}

CookieTab.propTypes = {
    cookieCategory: PropTypes.object.isRequired,
};

export default CookieTab;