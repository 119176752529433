import {Categories} from "@/types/pageSlice/Categories.type";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import useLocale from "@Hooks/selector/useLocale";

interface useCreateCompanySpecificMenuItemsAttributes {
    urlsForHeaderMenu: Record<Categories, UrlObjectType[]>,
    companyId: string
}

export default function useCreateCompanySpecificMenuItems({urlsForHeaderMenu, companyId}: useCreateCompanySpecificMenuItemsAttributes) {
    let customMenuItems = [];
    const locale = useLocale()
    if (companyId === "815") {
        customMenuItems = [
            {
                titleKey: locale === "hr" ? "sys.bus" : "menu.main.buses",
                list: urlsForHeaderMenu.bus,
                key: "buses",
            },
            {
                titleKey: locale === "hr" ? "sys.train" : "menu.main.trains",
                list: urlsForHeaderMenu.train,
                key: "trains",
            },
            {
                titleKey: locale === "hr" ? "sys.plane" : "menu.main.flights",
                list: urlsForHeaderMenu.plane,
                key: "flights",
            },
            {
                titleKey: locale === "hr" ? "sys.ferry" : "menu.main.ferries",
                list: urlsForHeaderMenu.ferry,
                key: "ferries",
            },
        ]
    } else if (companyId === "405") {
        customMenuItems = [
            {
                titleKey: "menu.main.ferries",
                list: urlsForHeaderMenu.ferry,
                key: "ferries",
            },
            {
                titleKey: "menu.main.croatia_islands",
                list: urlsForHeaderMenu.croatianIslands,
                key: "croatian_islands",
            },
            {
                titleKey: "menu.main.asia_islands",
                list: urlsForHeaderMenu.asianIslands,
                key: "asian_islands",
            },
            {
                titleKey: "menu.main.buses",
                list: urlsForHeaderMenu.bus,
                key: "buses",
            },
        ]
    }

    return customMenuItems
}