import {useAppSelector} from "@Hooks/selector/useAppSelector";
import assetUrlGenerator from "@Images/functions/assetUrlGenerator";
import useForm from "@Form/hooks/useForm";
import formatDate from "@DateTime/functions/formatDate";

export default function useCreateGbtDefaultVehicle() {
    const assetsBaseUrl = useAppSelector((state) => state?.page.assetsBaseUrl)
    const gbtImageUrlStandardVehicle = assetUrlGenerator(assetsBaseUrl, 'banner/standard.png')
    const {date, fromId, toId, fromSlug, toSlug} = useForm("urlParams")
    const gbtHomepageUrl = useAppSelector((state) => state?.page.gbtUrl)
    const formattedDate = formatDate(date, 'dd-MM-yyyy')

    return {
        maxNumberOfPassengers: 3,
        priceWithCurrency: '',
        link: `${gbtHomepageUrl}/en/booking/${fromSlug}-${fromId}/${toSlug}-${toId}/${formattedDate}/1`,
        imageUrl: gbtImageUrlStandardVehicle
    }
}