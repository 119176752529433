import {useAppSelector} from "@Hooks/selector/useAppSelector";
import MobileMenuItem from "@User/components/Mobile/MobileMenuItem";
import MobileMenuIcons from "@User/components/Mobile/MobileMenuIcons";
import React from "react";
import {PagesType} from "@/types/pageSlice/Pages.type";
import UrlObjectType from "@/types/pageSlice/UrlObject.type";
import {DisplayedPagesType} from "@User/types/DisplayedPages.type";
import useCompanyStyle from "@Hooks/selector/useCompanyStyle";
import {localeSpecificMenuItems} from "@Header/constants/localeSpecificMenuItems";
import useIsModifiedMenuDisplayed from "@Header/hooks/useIsModifiedMenuDisplayed";

function MobileMenuItemsList() {
    const urlsObject = useAppSelector((state) => (state.page.urlsObject))
    const {company_id: companyId} = useCompanyStyle()
    const displayFAQ = !(companyId === "815")
    const isModifiedMenuDisplayed = useIsModifiedMenuDisplayed()
    const visibleLinks = []

    if (isModifiedMenuDisplayed) {
        localeSpecificMenuItems.map((item) => {
            const menuItem = Object.entries(urlsObject).find((entry: [PagesType, UrlObjectType]) => {
                const [key, urlObject] = entry
                return key === item
            })

            if (menuItem && menuItem[1].text !== "") {
                visibleLinks.push(menuItem)
            }
        })
    } else {
        Object.entries(urlsObject).map((entry: [PagesType, UrlObjectType]) => {
            const [key, urlObject] = entry
            if (!displayFAQ && urlObject.routeName === "gbb_front_faq") {
                return
            }
            if (urlObject.routeName !== window.currentRoute && urlObject.text !== '') {
                visibleLinks.push(entry)
            }
        })
    }

    return (
        <>
            {visibleLinks.map(([key, urlObject]: [PagesType, UrlObjectType]) => (
                <MobileMenuItem
                    key={urlObject.text}
                    text={urlObject.text}
                    icon={<MobileMenuIcons tag={key as DisplayedPagesType}/>}
                    openItem={() => {
                        window.location.href = urlObject.url
                    }}
                />
            ))}
        </>
    )
}

export default MobileMenuItemsList